import { getGraphqlClient } from "@/api/graphql/client";
import { GlobalSearchDocument } from "@/api/graphql/generated/graphql";

import { DEFAULT_LIMIT } from "./constants";

export const getGlobalSearch = async (terms: string) => {
  const response = await getGraphqlClient().request(GlobalSearchDocument, {
    searchQuery: terms || "",
    limitUsers: DEFAULT_LIMIT,
    limitAccounts: DEFAULT_LIMIT,
  });

  return response;
};
