import styled from "@emotion/styled";
import {
  CustomTable,
  Text,
  TextField,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { PageHeader } from "@/components/shared/PageHeader";

export const StyledMigrationReportTextAreaContainer = styled("div")``;
export const StyledTextArea = styled(TextField)``;
export const StyledSearchButton = styled("div")``;
export const StyledTextAreaContainer = styled("div")``;
export const StyledMigrationReportsCount = styled("div")``;
export const StyledCountText = styled(Text)``;

export const StyledPageHeader = styled(PageHeader)`
  justify-content: space-between;
  padding: 0 40px 20px 40px;
`;

export const StyledCustomTable = styled(CustomTable)`
  max-height: 500px;
  margin: 0px important!;
  th {
    min-width: 120px;
  }
`;

//TODO: add min-width in CustomTable (ZAP)
export const StyledMigrationReportContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${StyledMigrationReportTextAreaContainer} {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
    align-items: end;
    width: 100%;

    ${StyledTextAreaContainer} {
      width: 100%;
      ${StyledTextArea} {
        margin: 0 16px;
      }
    }

    ${StyledSearchButton} {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      margin: 0 16px;
    }
  }

  ${StyledMigrationReportsCount} {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin: 0 16px;
  }
`;
