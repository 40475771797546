import { useQuery } from "@tanstack/react-query";

import { queries } from "@/api/graphql/constants.ts";

import { getMigrationStatus } from "./api";
import { mapMigrationStatusFromGraphQL } from "./mappers";
import { GetMigrationStatusResponse } from "./types";

export const useGetMigrationStatuses = (): GetMigrationStatusResponse => {
  const response = useQuery({
    queryKey: [queries.GET_MIGRATION_STATUS],
    queryFn: async () => await getMigrationStatus(),
  });

  const data = response.data
    ? mapMigrationStatusFromGraphQL(response.data)
    : undefined;

  return {
    data,
    isError: response.isError,
    isLoading: response.isLoading,
  };
};
