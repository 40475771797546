import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

import { MigrationStatus as MigrationStatusComponent } from "@/components/migrationStatus";
import { Loading } from "@/components/shared/Loading";
import { useAbilities } from "@/hooks/ability/hooks";

const MigrationStatus = () => {
  const { cannot, isLoading } = useAbilities();
  const router = useNavigate();

  useEffect(() => {
    if (!isLoading && cannot("MAINTAINER")) {
      router({ to: "/" });
    }
  }, [cannot, isLoading, router]);

  return (
    <div>
      <Loading isLoading={isLoading}>
        <MigrationStatusComponent />
      </Loading>
    </div>
  );
};

export const Route = createFileRoute("/migration-status/")({
  component: () => <MigrationStatus />,
});
