import {
  MigrationReportsBO,
  MigrationResult,
} from "@/api/graphql/migration/types.ts";

import { GetMiddleMsBuildAccountsMigrationReportsQuery } from "../types/graphql";

export const mapMigrationReportsFromGraphQL = (
  data: GetMiddleMsBuildAccountsMigrationReportsQuery,
): MigrationReportsBO => ({
  diagnosedAccounts:
    data.MiddleMsBuildAccountsMigrationReport.diagnosedAccounts,
  migratedAccounts: data.MiddleMsBuildAccountsMigrationReport.migratedAccounts,
  failedMigrations:
    data.MiddleMsBuildAccountsMigrationReport.failures?.map(
      (entry) =>
        Object.fromEntries(
          Object.entries(entry).map(([key, value]) => [
            key,
            typeof value === "boolean" ? (value ? "yes" : "no") : value,
          ]),
        ) as MigrationResult,
    ) || [],
  successfulMigrations:
    data.MiddleMsBuildAccountsMigrationReport.successes?.map(
      (entry) =>
        Object.fromEntries(
          Object.entries(entry).map(([key, value]) => [
            key,
            typeof value === "boolean" ? (value ? "yes" : "no") : value,
          ]),
        ) as MigrationResult,
    ) || [],
});
