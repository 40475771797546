import { InputAdornment, Popper, useTheme } from "@mui/material";
import {
  Autocomplete,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { EntityType, useGlobalSearch } from "@/api/graphql/globalSearch";

import SearchExample from "./details/example/component";
import SearchGroup from "./details/group/component";
import SearchSingleOption from "./details/single/component";
import { useSearchAutocomplete } from "./hooks";
import {
  getTextFieldInputSx,
  StyledAdornmentContainer,
  StyledDiv,
  StyledIconContainer,
  StyledPopOverContainer,
  StyledSearchField,
  StyledSearchIcon,
} from "./styles";
import { SearchResultOption } from "./types";

// @ts-expect-error - @TODO: WATT-1652 fix this to avoid TypeScript errors
const PopperMy = (props) => (
  <Popper sx={{ width: "100%" }} {...props}>
    {props.searchTerm?.length > 2 ? (
      <div data-testid='entities__entities-search-content'>
        {props.children}
      </div>
    ) : (
      <StyledPopOverContainer>
        <StyledDiv sx={{ padding: "30px" }}>
          <SearchExample />
        </StyledDiv>
      </StyledPopOverContainer>
    )}
  </Popper>
);

// @ts-expect-error - @TODO: WATT-1652 fix this to avoid TypeScript errors
const PaperMy = (props) => {
  const { t: tSearch } = useTranslation("search");

  return (
    <StyledPopOverContainer {...props}>
      <StyledDiv sx={{ padding: "15px" }}>
        {props.options.length > 0 && (
          <Text
            sx={{ marginBottom: "10px", color: "#0077FB" }}
            variant='title3'
            dataTestId='entities__entities-search-results'
          >
            {tSearch("search.result", {
              count:
                Number(props.totalAccountResults) +
                Number(props.totalUsersResults),
              term: props.searchTerm,
            })}
          </Text>
        )}

        <div>
          {Array.isArray(props.children)
            ? React.Children.map(props.children, (child) => child)
            : props.children}
        </div>
      </StyledDiv>
    </StyledPopOverContainer>
  );
};

const Search = () => {
  const theme = useTheme();
  const { t: tSearch } = useTranslation("search");

  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: options,
    isLoading,
    totalAccountResults,
    totalUsersResults,
  } = useGlobalSearch(searchTerm);

  const { handleHighlightChange, handleKeyDown, setHighlightedOption } =
    useSearchAutocomplete();

  return (
    <Autocomplete<SearchResultOption>
      id='autocomplete'
      options={options}
      loading={isLoading}
      onClose={() => {
        setSearchTerm("");
        setHighlightedOption(null);
      }}
      autoHighlight
      clearOnBlur
      isOptionEqualToValue={(
        option: SearchResultOption,
        value: SearchResultOption,
      ) => option.id === value.id}
      groupBy={(option: SearchResultOption) => option.type}
      popupIcon={null}
      noOptionsText={<SearchExample isNoOptionsVersion />}
      onHighlightChange={handleHighlightChange}
      filterOptions={(options: SearchResultOption[]) => options}
      getOptionLabel={(option: SearchResultOption) =>
        option.type === EntityType.User
          ? `${option.firstName} ${option.lastName}`
          : option.name
      }
      PaperComponent={PaperMy}
      PopperComponent={PopperMy}
      // @ts-expect-error - @TODO: WATT-1652 fix this to avoid TypeScript errors
      renderOption={(params, option, { inputValue }) => (
        <SearchSingleOption
          key={option.id}
          params={params}
          option={option}
          inputValue={inputValue}
        />
      )}
      // @ts-expect-error - @TODO: WATT-1652 fix this to avoid TypeScript errors
      renderGroup={(params) =>
        options.length > 0 && (
          <SearchGroup
            key={params.key}
            params={params}
            totalUsersResults={totalUsersResults}
            totalAccountResults={totalAccountResults}
          />
        )
      }
      slotProps={{
        popper: { searchTerm },
        paper: { options, searchTerm, totalUsersResults, totalAccountResults },
      }}
      // @ts-expect-error - @TODO: WATT-1652 fix this to avoid TypeScript errors
      renderInput={(props) => (
        <StyledSearchField
          {...props}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={tSearch("search.search")}
          data-testid='entities__entities-search-bar'
          InputProps={{
            ...props.InputProps,
            sx: getTextFieldInputSx(theme),
            startAdornment: (
              <InputAdornment position='start'>
                <StyledAdornmentContainer>
                  <StyledIconContainer>
                    <StyledSearchIcon width={18} height={18} />
                  </StyledIconContainer>
                </StyledAdornmentContainer>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default Search;
