export enum EntityType {
  Company = "Company",
  User = "User",
}

export type UserDataType = {
  id: string;
  type: EntityType.User;
  email: string;
  firstName: string;
  lastName: string;
};

export type CompanyDataType = {
  id: string;
  type: EntityType.Company;
  number: string;
  name: string;
};
