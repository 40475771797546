import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";

import { toAccountUsersBO } from "@/api/graphql/accounts/getAccountUsers/mappers.ts";
import { UseGetAccountUsersProps } from "@/api/graphql/accounts/getAccountUsers/types.ts";
import { queries } from "@/api/graphql/constants.ts";
import { getNextPageParam } from "@/helpers/api/getNextPageParam";

import { DEFAULT_FIRST_PAGE } from "../../agents/search";
import { getAccountUsers } from "./api";
import { ACCOUNT_USERS_PER_PAGE } from "./constants";


export const useGetAccountUsers = ({
  accountId,
  searchTerm,
}: UseGetAccountUsersProps) => {
  const response = useInfiniteQuery({
    initialPageParam: DEFAULT_FIRST_PAGE,
    placeholderData: keepPreviousData,
    queryKey: [queries.GET_ACCOUNT_USERS, { accountId, searchTerm }],
    queryFn: async ({ pageParam = DEFAULT_FIRST_PAGE }) => {
      try {
        const accountUsersData = await getAccountUsers({
          filters: {
            accountId,
            query: {
              user: searchTerm.length > 2 ? searchTerm : "",
            },
          },
          paging: {
            page: pageParam,
            size: ACCOUNT_USERS_PER_PAGE,
          },
        });

        return accountUsersData;
      } catch {
        return;
      }
    },
    getNextPageParam: (lastPage, allPages) =>
      getNextPageParam({
        lastPage,
        allPages,
        key: "AccountsMsSearchPrivileges",
      }),
  });

  return {
    data: response?.data ? toAccountUsersBO(response.data) : undefined,
    error: response.error,
    isLoading: response.isLoading,
    fetchNextPage: response.fetchNextPage,
    hasNextPage: response.hasNextPage,
    totalUsers:
      response.data?.pages[0]?.AccountsMsSearchPrivileges.pagination.total ?? 0,
  };
};
