import styled from "@mui/material/styles/styled";
import {
  Drawer,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { RoundedTopLeftContainer } from "@/components/containers/RoundedTopLeftContainer";
export const StyledDrawer = styled(Drawer)`
  min-width: 500px;
`;

export const StyledRoundedTopLeftContainer = styled(RoundedTopLeftContainer)`
  margin-top: 20px;
`;

export const StyledDrawerInnerContainer = styled("div")`
  padding-block: 30px;
`;

export const StyledSection = styled("section")`
  margin-bottom: 20px;
`;

export const StyledInputContainer = styled("div")`
  margin-bottom: 20px;
`;

export const StyledForm = styled("form")``;

export const StyledText = styled(Text)`
  margin-bottom: 10px;
`;
