import { BubbleTextProps } from "@totalenergiescode/mobility-business-rev-design-system";

import { MigrationResult } from "@/api/graphql/migration/types";

export const STATUS_LABELS: Record<string, string> = {
  yes: "migrationReport.status.yes",
  no: "migrationReport.status.no",
};

export const STATUS_COLORS: Record<string, BubbleTextProps["variant"]> = {
  yes: "red",
  no: "green",
};

export const MIGRATION_REPORT_COLUMN_KEYS: Array<keyof MigrationResult> = [
  "accountNumber",
  "accountHasNoPcom",
  "accountNumberIsAct",
  "billingAccountPayerCurrencyIsNull",
  "contactEmailIsNull",
  "contactEmailIsInvalid",
  "contactIsNull",
  "contractBillingFrequencyCodeIsNull",
  "contractContractNumIsNull",
  "contractExternalContractIdIsNull",
  "contractHasInvalidStatus",
  "contractHasNoScom",
  "contractIsNotMb2",
  "contractMissing",
  "contractOfferCodeIsNull",
  "contractOfferNameIsNull",
  "notPresentInAccountsService",
];

export const MAP_COLUMN_KEYS_TO_TRANSLATION_KEYS: Record<
  keyof MigrationResult,
  string
> = {
  accountNumber: "migrationReport.columnNames.accountNumber",
  accountHasNoPcom: "migrationReport.columnNames.accountHasNoPcom",
  accountNumberIsAct: "migrationReport.columnNames.accountNumberIsAct",
  billingAccountPayerCurrencyIsNull:
    "migrationReport.columnNames.billingAccountPayerCurrencyIsNull",
  contactEmailIsNull: "migrationReport.columnNames.contactEmailIsNull",
  contactIsNull: "migrationReport.columnNames.contactIsNull",
  contractBillingFrequencyCodeIsNull:
    "migrationReport.columnNames.contractBillingFrequencyCodeIsNull",
  contactEmailIsInvalid: "migrationReport.columnNames.contactEmailIsInvalid",
  contractContractNumIsNull:
    "migrationReport.columnNames.contractContractNumIsNull",
  contractExternalContractIdIsNull:
    "migrationReport.columnNames.contractExternalContractIdIsNull",
  contractHasInvalidStatus:
    "migrationReport.columnNames.contractHasInvalidStatus",
  contractHasNoScom: "migrationReport.columnNames.contractHasNoScom",
  contractIsNotMb2: "migrationReport.columnNames.contractIsNotMb2",
  contractMissing: "migrationReport.columnNames.contractMissing",
  contractOfferCodeIsNull:
    "migrationReport.columnNames.contractOfferCodeIsNull",
  contractOfferNameIsNull:
    "migrationReport.columnNames.contractOfferNameIsNull",
  notPresentInAccountsService:
    "migrationReport.columnNames.notPresentInAccountsService",
};
