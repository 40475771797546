import { graphql } from "@/api/graphql/types/gql.ts";

export const getFormDataRuleGroupsQuery = graphql(`
  query GetFormDataRuleGroups(
    $request: MiddleMsSearchFormDataRuleGroupsRequest!
  ) {
    MiddleMsSearchFormDataRuleGroups(request: $request) {
      data {
        codes
        description
        disabledFor
        enabled
        enabledFor
        id
        name
        numberOfRules
        rules
      }
    }
  }
`);
