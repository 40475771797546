/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthCallbackImport } from './routes/auth-callback'
import { Route as MigrationStatusIndexImport } from './routes/migration-status/index'
import { Route as MigrationReportIndexImport } from './routes/migration-report/index'
import { Route as FormdataInjectorIndexImport } from './routes/formdata-injector/index'
import { Route as BackofficeUsersIndexImport } from './routes/backoffice-users/index'
import { Route as AccountsIdImport } from './routes/accounts/$id'
import { Route as UsersIdIndexImport } from './routes/users/$id/index'
import { Route as BackofficeUsersAddIndexImport } from './routes/backoffice-users/add/index'
import { Route as UsersIdNotificationsIndexImport } from './routes/users/$id/notifications/index'

// Create Virtual Routes

const IndexLazyImport = createFileRoute('/')()

// Create/Update Routes

const AuthCallbackRoute = AuthCallbackImport.update({
  id: '/auth-callback',
  path: '/auth-callback',
  getParentRoute: () => rootRoute,
} as any)

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const MigrationStatusIndexRoute = MigrationStatusIndexImport.update({
  id: '/migration-status/',
  path: '/migration-status/',
  getParentRoute: () => rootRoute,
} as any)

const MigrationReportIndexRoute = MigrationReportIndexImport.update({
  id: '/migration-report/',
  path: '/migration-report/',
  getParentRoute: () => rootRoute,
} as any)

const FormdataInjectorIndexRoute = FormdataInjectorIndexImport.update({
  id: '/formdata-injector/',
  path: '/formdata-injector/',
  getParentRoute: () => rootRoute,
} as any)

const BackofficeUsersIndexRoute = BackofficeUsersIndexImport.update({
  id: '/backoffice-users/',
  path: '/backoffice-users/',
  getParentRoute: () => rootRoute,
} as any)

const AccountsIdRoute = AccountsIdImport.update({
  id: '/accounts/$id',
  path: '/accounts/$id',
  getParentRoute: () => rootRoute,
} as any)

const UsersIdIndexRoute = UsersIdIndexImport.update({
  id: '/users/$id/',
  path: '/users/$id/',
  getParentRoute: () => rootRoute,
} as any)

const BackofficeUsersAddIndexRoute = BackofficeUsersAddIndexImport.update({
  id: '/backoffice-users/add/',
  path: '/backoffice-users/add/',
  getParentRoute: () => rootRoute,
} as any)

const UsersIdNotificationsIndexRoute = UsersIdNotificationsIndexImport.update({
  id: '/users/$id/notifications/',
  path: '/users/$id/notifications/',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/auth-callback': {
      id: '/auth-callback'
      path: '/auth-callback'
      fullPath: '/auth-callback'
      preLoaderRoute: typeof AuthCallbackImport
      parentRoute: typeof rootRoute
    }
    '/accounts/$id': {
      id: '/accounts/$id'
      path: '/accounts/$id'
      fullPath: '/accounts/$id'
      preLoaderRoute: typeof AccountsIdImport
      parentRoute: typeof rootRoute
    }
    '/backoffice-users/': {
      id: '/backoffice-users/'
      path: '/backoffice-users'
      fullPath: '/backoffice-users'
      preLoaderRoute: typeof BackofficeUsersIndexImport
      parentRoute: typeof rootRoute
    }
    '/formdata-injector/': {
      id: '/formdata-injector/'
      path: '/formdata-injector'
      fullPath: '/formdata-injector'
      preLoaderRoute: typeof FormdataInjectorIndexImport
      parentRoute: typeof rootRoute
    }
    '/migration-report/': {
      id: '/migration-report/'
      path: '/migration-report'
      fullPath: '/migration-report'
      preLoaderRoute: typeof MigrationReportIndexImport
      parentRoute: typeof rootRoute
    }
    '/migration-status/': {
      id: '/migration-status/'
      path: '/migration-status'
      fullPath: '/migration-status'
      preLoaderRoute: typeof MigrationStatusIndexImport
      parentRoute: typeof rootRoute
    }
    '/backoffice-users/add/': {
      id: '/backoffice-users/add/'
      path: '/backoffice-users/add'
      fullPath: '/backoffice-users/add'
      preLoaderRoute: typeof BackofficeUsersAddIndexImport
      parentRoute: typeof rootRoute
    }
    '/users/$id/': {
      id: '/users/$id/'
      path: '/users/$id'
      fullPath: '/users/$id'
      preLoaderRoute: typeof UsersIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/users/$id/notifications/': {
      id: '/users/$id/notifications/'
      path: '/users/$id/notifications'
      fullPath: '/users/$id/notifications'
      preLoaderRoute: typeof UsersIdNotificationsIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/auth-callback': typeof AuthCallbackRoute
  '/accounts/$id': typeof AccountsIdRoute
  '/backoffice-users': typeof BackofficeUsersIndexRoute
  '/formdata-injector': typeof FormdataInjectorIndexRoute
  '/migration-report': typeof MigrationReportIndexRoute
  '/migration-status': typeof MigrationStatusIndexRoute
  '/backoffice-users/add': typeof BackofficeUsersAddIndexRoute
  '/users/$id': typeof UsersIdIndexRoute
  '/users/$id/notifications': typeof UsersIdNotificationsIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/auth-callback': typeof AuthCallbackRoute
  '/accounts/$id': typeof AccountsIdRoute
  '/backoffice-users': typeof BackofficeUsersIndexRoute
  '/formdata-injector': typeof FormdataInjectorIndexRoute
  '/migration-report': typeof MigrationReportIndexRoute
  '/migration-status': typeof MigrationStatusIndexRoute
  '/backoffice-users/add': typeof BackofficeUsersAddIndexRoute
  '/users/$id': typeof UsersIdIndexRoute
  '/users/$id/notifications': typeof UsersIdNotificationsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/auth-callback': typeof AuthCallbackRoute
  '/accounts/$id': typeof AccountsIdRoute
  '/backoffice-users/': typeof BackofficeUsersIndexRoute
  '/formdata-injector/': typeof FormdataInjectorIndexRoute
  '/migration-report/': typeof MigrationReportIndexRoute
  '/migration-status/': typeof MigrationStatusIndexRoute
  '/backoffice-users/add/': typeof BackofficeUsersAddIndexRoute
  '/users/$id/': typeof UsersIdIndexRoute
  '/users/$id/notifications/': typeof UsersIdNotificationsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/auth-callback'
    | '/accounts/$id'
    | '/backoffice-users'
    | '/formdata-injector'
    | '/migration-report'
    | '/migration-status'
    | '/backoffice-users/add'
    | '/users/$id'
    | '/users/$id/notifications'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/auth-callback'
    | '/accounts/$id'
    | '/backoffice-users'
    | '/formdata-injector'
    | '/migration-report'
    | '/migration-status'
    | '/backoffice-users/add'
    | '/users/$id'
    | '/users/$id/notifications'
  id:
    | '__root__'
    | '/'
    | '/auth-callback'
    | '/accounts/$id'
    | '/backoffice-users/'
    | '/formdata-injector/'
    | '/migration-report/'
    | '/migration-status/'
    | '/backoffice-users/add/'
    | '/users/$id/'
    | '/users/$id/notifications/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  AuthCallbackRoute: typeof AuthCallbackRoute
  AccountsIdRoute: typeof AccountsIdRoute
  BackofficeUsersIndexRoute: typeof BackofficeUsersIndexRoute
  FormdataInjectorIndexRoute: typeof FormdataInjectorIndexRoute
  MigrationReportIndexRoute: typeof MigrationReportIndexRoute
  MigrationStatusIndexRoute: typeof MigrationStatusIndexRoute
  BackofficeUsersAddIndexRoute: typeof BackofficeUsersAddIndexRoute
  UsersIdIndexRoute: typeof UsersIdIndexRoute
  UsersIdNotificationsIndexRoute: typeof UsersIdNotificationsIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  AuthCallbackRoute: AuthCallbackRoute,
  AccountsIdRoute: AccountsIdRoute,
  BackofficeUsersIndexRoute: BackofficeUsersIndexRoute,
  FormdataInjectorIndexRoute: FormdataInjectorIndexRoute,
  MigrationReportIndexRoute: MigrationReportIndexRoute,
  MigrationStatusIndexRoute: MigrationStatusIndexRoute,
  BackofficeUsersAddIndexRoute: BackofficeUsersAddIndexRoute,
  UsersIdIndexRoute: UsersIdIndexRoute,
  UsersIdNotificationsIndexRoute: UsersIdNotificationsIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/auth-callback",
        "/accounts/$id",
        "/backoffice-users/",
        "/formdata-injector/",
        "/migration-report/",
        "/migration-status/",
        "/backoffice-users/add/",
        "/users/$id/",
        "/users/$id/notifications/"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/auth-callback": {
      "filePath": "auth-callback.tsx"
    },
    "/accounts/$id": {
      "filePath": "accounts/$id.tsx"
    },
    "/backoffice-users/": {
      "filePath": "backoffice-users/index.tsx"
    },
    "/formdata-injector/": {
      "filePath": "formdata-injector/index.tsx"
    },
    "/migration-report/": {
      "filePath": "migration-report/index.tsx"
    },
    "/migration-status/": {
      "filePath": "migration-status/index.tsx"
    },
    "/backoffice-users/add/": {
      "filePath": "backoffice-users/add/index.tsx"
    },
    "/users/$id/": {
      "filePath": "users/$id/index.tsx"
    },
    "/users/$id/notifications/": {
      "filePath": "users/$id/notifications/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
