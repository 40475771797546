import { match } from "ts-pattern";

import { MigrationStatusEnum } from "@/api/graphql/migration/status/types";

export const getMigrationStatusBubbleProps = (status: MigrationStatusEnum) =>
  match(status)
    .with(MigrationStatusEnum.ERROR, () => ({
      variant: "red",
      translationKey: "migrationStatus.status.error",
    }))
    .with(MigrationStatusEnum.IN_PROGRESS, () => ({
      variant: "orange",
      translationKey: "migrationStatus.status.inProgress",
    }))
    .with(MigrationStatusEnum.SUCCESS, () => ({
      variant: "green",
      translationKey: "migrationStatus.status.success",
    }))
    .otherwise(() => ({
      variant: "grey",
      translationKey: "migrationStatus.status.unknown",
    }));
