import { Link } from "@tanstack/react-router";
import {
  Divider,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { EntityType } from "@/api/graphql/globalSearch";
import {
  StyledDiv,
  StyledLeftSideOptionContainer,
  StyledOfficeIcon,
  StyledOptionContainer,
  StyledPersonIcon,
} from "@/components/Search/styles";
import { getHighlightedText } from "@/helpers/search/getHighlightedText";

import { HighlightTextProps, SearchSingleOptionProps } from "./types";

const HighlightText = ({ fullText, textToHighlight }: HighlightTextProps) => (
  <Text fontWeight='regular' variant='title1'>
    <span
      dangerouslySetInnerHTML={{
        __html: getHighlightedText(fullText, textToHighlight),
      }}
    />
  </Text>
);

const SearchSingleOption = ({
  params,
  option,
  inputValue,
}: SearchSingleOptionProps) => (
  <Link
    to={option.type === EntityType.User ? "/users/$id" : "/accounts/$id"}
    params={{ id: option.id }}
  >
    <StyledOptionContainer
      {...params}
      data-testid='entities__entities-search-option'
    >
      <StyledLeftSideOptionContainer>
        {option.type === EntityType.User ? (
          <StyledPersonIcon width={40} height={24} />
        ) : (
          <StyledOfficeIcon width={44} height={28} />
        )}
        <HighlightText
          textToHighlight={inputValue}
          fullText={
            option.type === EntityType.User
              ? option.firstName + " " + option.lastName
              : option.name
          }
        />
      </StyledLeftSideOptionContainer>
      <StyledDiv sx={{ width: "50%" }}>
        <HighlightText
          textToHighlight={inputValue}
          fullText={
            option.type === EntityType.User ? option.email : option.number
          }
        />
      </StyledDiv>
    </StyledOptionContainer>
    <Divider />
  </Link>
);

export default SearchSingleOption;
