import { graphql } from "@/api/graphql/types/gql.ts";

export const MiddleMsCreateFormDataRuleGroup = graphql(`
  mutation MiddleMsCreateFormDataRuleGroup(
    $request: MiddleMsCreateFormDataRuleGroupRequest!
  ) {
    MiddleMsCreateFormDataRuleGroup(request: $request) {
      id
    }
  }
`);
