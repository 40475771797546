import React from "react";
import { useTranslation } from "react-i18next";

import { useGetMigrationStatuses } from "@/api/graphql/migration/status";
import { RoundedTopLeftContainer } from "@/components/containers/RoundedTopLeftContainer";
import { Loading } from "@/components/shared/Loading";

import { useGetMigrationStatusTable, useMigrationStatusFilter } from "./hooks";
import {
  StyledContainer,
  StyledCurrentCountOnTotalContainer,
  StyledCurrentCountOnTotalText,
  StyledCustomTable,
  StyledDivider,
  StyledFiltersContainer,
  StyledPageHeader,
  StyledSelect,
} from "./styles";
import { MigrationStatusFilter } from "./types";

export const MigrationStatus = () => {
  const { t: tCommon } = useTranslation("common");
  const { t: tMigrationStatus } = useTranslation("migrationStatus");

  const { data, isLoading } = useGetMigrationStatuses();
  const { filtered, selected, onChange } = useMigrationStatusFilter(data);
  const { columns, rows } = useGetMigrationStatusTable(filtered);

  const breadcrumbItems = [
    {
      title: tCommon("common.features.dashboard"),
      path: "/" as const,
    },
    {
      title: tCommon("common.sidebar.migrationStatus"),
      path: "/migration-status" as const,
    },
  ];

  const filtersOptions = [
    {
      label: tMigrationStatus("migrationStatus.filters.none"),
      value: MigrationStatusFilter.NONE,
    },
    {
      label: tMigrationStatus("migrationStatus.filters.fromMb1"),
      value: MigrationStatusFilter.FROM_MB1,
    },
  ];

  return (
    <StyledContainer>
      <StyledPageHeader
        breadcrumbItems={breadcrumbItems}
        title={tCommon("common.sidebar.migrationStatus")}
        href=''
      />
      <RoundedTopLeftContainer>
        <StyledFiltersContainer>
          <StyledSelect
            type='options'
            dataTestId='migration-status-filters-select'
            label={tMigrationStatus("migrationStatus.filters.title")}
            options={filtersOptions}
            value={selected}
            onChange={onChange}
          />
        </StyledFiltersContainer>

        <StyledDivider />

        <Loading isLoading={isLoading}>
          <React.Fragment>
            <StyledCustomTable rows={rows} columns={columns} />
            <StyledCurrentCountOnTotalContainer>
              <StyledCurrentCountOnTotalText variant='sub3'>
                {tMigrationStatus("migrationStatus.pagination.name", {
                  count: filtered?.length ?? 0,
                  total: data?.length ?? 0,
                })}
              </StyledCurrentCountOnTotalText>
            </StyledCurrentCountOnTotalContainer>
          </React.Fragment>
        </Loading>
      </RoundedTopLeftContainer>
    </StyledContainer>
  );
};
