import { useCallback, useMemo } from "react";

import { UseAbilitiesReturnType } from "@/types/ability";

import { useMe } from "../session/useMe";

export const useAbilities = (): UseAbilitiesReturnType => {
  const { userInfo, isLoading } = useMe();
  const rights = userInfo.rights;
  const can = useCallback(
    (role: string): boolean => rights.includes(role) || false,
    [rights],
  );
  const cannot = useCallback((role: string): boolean => !can(role), [can]);
  const hasOnlyMaintainer = useMemo(
    (): boolean => rights.length === 1 && rights[0] === "MAINTAINER",
    [rights],
  );

  return { can, cannot, isLoading, rights, hasOnlyMaintainer };
};
