import yamlValidator from "js-yaml";

import { ValidateYamlProps } from "./types";

export const validateYaml = ({
  setLocalError,
  setError,
  yamlContent,
}: ValidateYamlProps) => {
  try {
    yamlValidator.load(yamlContent);
    setLocalError(null);
    setError?.(null);
  } catch (err) {
    const errorMessage =
      err instanceof Error ? err.message : "Erreur YAML inconnue.";
    setLocalError(errorMessage);
    setError?.(errorMessage);
  }
};
