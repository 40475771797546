import { SnackbarProps } from "@totalenergiescode/mobility-business-rev-design-system";
import { z } from "zod";

import {
  MAX_LENGTH_FIELDS,
  MIN_LENGTH_FIELDS,
} from "@/components/formDataInjector/constants";

export const ruleFormSchema = z.object({
  id: z.string().optional(),
  numberOfRules: z.number().optional(),
  name: z
    .string()
    .min(MIN_LENGTH_FIELDS, "formDataInjector.forms.errors.required")
    .max(MAX_LENGTH_FIELDS, "formDataInjector.forms.errors.maxLength")
    .trim(),
  description: z
    .string({
      required_error: "formDataInjector.forms.errors.required",
    })
    .min(MIN_LENGTH_FIELDS, "formDataInjector.forms.errors.required")
    .max(MAX_LENGTH_FIELDS, "formDataInjector.forms.errors.maxLength")
    .trim(),
  rules: z.string(),
  enabled: z.boolean().optional(),
  enabledFor: z.array(z.string()).optional(),
  disabledFor: z.array(z.string()).optional(),
  codes: z.array(z.string()).optional(),
});

export enum HandleFormDataRuleGroupType {
  TOGGLE = "toggle",
  DELETE = "delete",
  DUPLICATE = "duplicate",
  UPDATE = "update",
}

export type UseHandleFormDataRuleGroupProps = {
  resetState?: (value: undefined) => void;
  addSnackbar: (value: SnackbarProps) => void;
  translateFunction: (value: string) => string;
};

export type RuleFormType = z.infer<typeof ruleFormSchema>;
