import { getGraphqlClient } from "@/api/graphql/client.ts";
import { MiddleMsSearchFormDataRuleGroupsRequest } from "@/api/graphql/types/graphql.ts";

import { getFormDataRuleGroupsQuery } from "./queries.ts";

export const getSearchFormDataRuleGroups = async ({
  filters,
}: MiddleMsSearchFormDataRuleGroupsRequest) => {
  const graphqlClient = getGraphqlClient();

  return await graphqlClient.request(getFormDataRuleGroupsQuery, {
    request: { filters },
  });
};
