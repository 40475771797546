import { useMutation } from "@tanstack/react-query";

import { cancelSupportUserInviteQuery } from "@/api/graphql/agents/cancelSupportAgentInvite/api";

const useCancelSupportUserInvite = () => {
  const {
    mutateAsync: cancelSupportUserInvite,
    isPending,
    isSuccess,
    error,
  } = useMutation({
    mutationFn: async (id: string) => await cancelSupportUserInviteQuery({ id: id }),
    onSuccess: () => "success",
    onError: () => "error",
    });

  return {
    cancelSupportUserInvite,
    isPending,
    isSuccess,
    error,
  };
};

export { useCancelSupportUserInvite };