import { GetMiddleMsGetIngestionSnapshotQuery } from "@/api/graphql/types/graphql";
import { formatUnixTimestampToDate } from "@/utils/date";

import { MigrationStatusData, MigrationStatusEnum } from "./types";

export const mapMigrationStatusFromGraphQL = (
  data: GetMiddleMsGetIngestionSnapshotQuery,
): Array<MigrationStatusData> => {
  if (!data?.MiddleMsGetIngestionSnapshot?.flows) {
    return [];
  }

  return data.MiddleMsGetIngestionSnapshot.flows.map((flow) => ({
    code: flow.code,
    status: flow.status as unknown as MigrationStatusEnum,
    isEmpty: flow.isEmpty,
    isMb1: flow.isMb1,
    successfulTs: formatUnixTimestampToDate(flow.successfulTs, "DD/MM/YYYY - HH:mm:ss")
  }));
};
