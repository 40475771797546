import { graphql } from "@/api/graphql/types/gql.ts";

export const getMiddleMsBuildAccountsMigrationReportsQuery = graphql(`
  query GetMiddleMsBuildAccountsMigrationReports(
    $request: MiddleMsBuildAccountsMigrationReportRequest
  ) {
    MiddleMsBuildAccountsMigrationReport(request: $request) {
      migratedAccounts
      diagnosedAccounts
      failures {
        accountNumber
        contactEmailIsNull
        accountNumberIsAct
        accountHasNoPcom
        billingAccountPayerCurrencyIsNull
        contractOfferNameIsNull
        contractOfferCodeIsNull
        contactEmailIsInvalid
        contractBillingFrequencyCodeIsNull
        contractExternalContractIdIsNull
        contractContractNumIsNull
        contractIsNotMb2
        contractHasNoScom
        contractHasInvalidStatus
        notPresentInAccountsService
        contactIsNull
        contractMissing
      }
      successes {
        accountNumber
        contactEmailIsNull
        accountNumberIsAct
        accountHasNoPcom
        billingAccountPayerCurrencyIsNull
        contractOfferNameIsNull
        contractOfferCodeIsNull
        contractBillingFrequencyCodeIsNull
        contactEmailIsInvalid
        contractExternalContractIdIsNull
        contractContractNumIsNull
        contractIsNotMb2
        contractHasNoScom
        contractHasInvalidStatus
        notPresentInAccountsService
        contactIsNull
        contractMissing
      }
    }
  }
`);
