export enum MigrationStatusEnum {
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  UNKNOWN = "UNKNOWN",
}

export type MigrationStatusData = {
  code: string;
  status: MigrationStatusEnum;
  isEmpty: boolean;
  isMb1: boolean;
  successfulTs: string | null;
};

export type GetMigrationStatusResponse = {
  isLoading: boolean;
  isError: boolean;
  data: Array<MigrationStatusData> | undefined;
};
