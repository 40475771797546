import { useQuery } from "@tanstack/react-query";

import { SearchAccountsMsMeQuery } from "@/api/graphql/authorization/me/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { SupportUserInfo } from "@/hooks/session/useMe/types.ts";

const getMe = () => {
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request({
    document: SearchAccountsMsMeQuery,
    variables: {},
  });
};

export const useMe = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["Me"],
    queryFn: getMe,
  });
  const userInfo: SupportUserInfo = {
    firstname: data?.AccountsMsMe?.supportUserInfo.firstName || "",
    igg: data?.AccountsMsMe?.supportUserInfo.igg || "",
    lastname: data?.AccountsMsMe?.supportUserInfo.lastName || "",
    rights: data?.AccountsMsMe?.supportUserInfo.rights || [],
    subsidaries: data?.AccountsMsMe?.supportUserInfo.subsidiaries || [],
    email: data?.AccountsMsMe?.supportUserInfo.email || "",
  };

  return { data, isLoading, isError, error, userInfo };
};
