import { useDsTheme } from "@totalenergiescode/mobility-business-rev-design-system";
import { RFC } from "react";
import { useTranslation } from "react-i18next";

import { EmptyContent } from "@/components/shared/EmptyComponent";
import { StyledHighlightLeftCard } from "@/components/shared/EmptyComponent/styles";

import ErrorLottie from "../../../assets/animations/error.json";
import { Props } from "./types";

export const ErrorComponent: RFC<Props> = ({
  children,
  isError,
  dataTestId,
  principalText,
  secondaryText,
}) => {
  const theme = useDsTheme();
  const { t: tCommon } = useTranslation("common");

  return isError ? (
    <StyledHighlightLeftCard
      highlightColor={theme.palette.danger.danger[500] || ""}
      dataTestId={dataTestId}
    >
      <EmptyContent
        principalText={
          principalText ?? tCommon("common.errorMessage.principalText")
        }
        secondaryText={
          secondaryText ?? tCommon("common.errorMessage.secondaryText")
        }
        lottie={ErrorLottie}
      />
    </StyledHighlightLeftCard>
  ) : (
    children
  );
};
