import { ChangeEvent, useEffect, useState } from "react";

import { FormDataRuleGroupsFE } from "@/api/graphql/formDataInjector/getSearchFormDataRuleGroups/types";

export const useFilteredRules = (rules: FormDataRuleGroupsFE[] | undefined) => {
  const [queryString, setQueryString] = useState("");
  const [filteredRules, setFilteredRules] = useState<FormDataRuleGroupsFE[]>(
    [],
  );

  const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const searchValue = evt.target.value.toLowerCase();
    setQueryString(searchValue);

    if (rules) {
      const filtered = rules.filter(
        (rule) =>
          rule.name.toLowerCase().includes(searchValue) ||
          rule.description.toLowerCase().includes(searchValue),
      );
      setFilteredRules(filtered);
    }
  };

  useEffect(() => {
    if (queryString === "" && rules) {
      setFilteredRules(rules);
    }
  }, [queryString, rules]);

  return {
    queryString,
    filteredRules,
    handleSearchChange,
  };
};
