import { getGraphqlClient } from "@/api/graphql/client.ts";
import { NOTIFICATIONS_PER_PAGE } from "@/api/graphql/notifications/constants.ts";
import { GetNotificationsByUserQuery } from "@/api/graphql/notifications/queries.ts";
import { GetNotificationsRequest } from "@/api/graphql/notifications/types.ts";
import { GetNotificationsByUserQueryQuery } from "@/api/graphql/types/graphql.ts";

export const getNotificationsByUser = async ({
  filters,
  paging,
  sorts,
}: GetNotificationsRequest) => {
  const page = paging?.page || 1;
  const size = paging?.size || NOTIFICATIONS_PER_PAGE;
  const userId = filters?.userId || "";

  return await getGraphqlClient().request<GetNotificationsByUserQueryQuery>(
    GetNotificationsByUserQuery,
    {
      request: {
        filters: {
          userId,
        },
        paging: {
          page,
          size,
        },
        sorts,
      },
    },
  );
};
