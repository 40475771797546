import { styled } from "@mui/system";

export const StyledYamlEditorContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;

  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(183, 203, 211, 1);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  height: 100%;
  min-height: 500px;
`;

export const StyledError = styled("div")`
  color: ${(props) => props.theme.palette.danger.danger[500]};
  margin-top: 10px;
`;

export const StyledValid = styled("div")`
  color: ${(props) => props.theme.palette.danger.success[500]};
  margin-top: 10px;
`;
