import { graphql } from "@/api/graphql/types/gql";

export const SearchAccountsMsMeQuery = graphql(`
  query SearchAccountsMsMe($request: AccountsMsMeRequest) {
    AccountsMsMe(request: $request) {
      userId
      sharedUserProfileTs
      systemUserInfo
      supportUserInfo {
        igg
        email
        firstName
        lastName
        rights
        subsidiaries
      }
    }
  }
`);
