import { FormatDateType } from "@/utils/date/types";

import { dayjs } from "./config";

export const isValidDate = (date: Date) =>
  date instanceof Date && !isNaN(date.getTime());

export const formattedDate = ({
  dateString,
  locale = "fr-FR",
  formatType,
}: FormatDateType) => {
  const inputDate = new Date(dateString);
  const formatted = new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...(formatType === "date-time"
      ? {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      : {}),
    hour12: false,
    timeZone: "UTC",
  }).format(inputDate);
  const year = inputDate.getFullYear(); // Year in YYYY format
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Month with two digits
  const day = inputDate.getDate().toString().padStart(2, "0"); // Day with two digits
  const hours = inputDate.getHours().toString().padStart(2, "0"); // Hour with two digits
  const minutes = inputDate.getMinutes().toString().padStart(2, "0"); // Minute with two digits
  const seconds = inputDate.getSeconds().toString().padStart(2, "0"); // Second with two digits
  const milliseconds = inputDate.getMilliseconds().toString().padStart(3, "0");
  const formattedISODate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  const formattedNewDate = `${year}-${month}-${day}`;

  return formatType === "ISO"
    ? formattedISODate
    : formatType === "newFormatDate"
      ? formattedNewDate
      : formatted;
};

/**
 * Timestamps from the api may be in seconds, this function returns a date by automatically transforming timestamps in milliseconds
 * If the timestamp is already is milliseconds, it is not changed
 * The backend is expected to be updated to send timestamps in milliseconds, making this function obsolete
 */
export const TEMPORARY_timestampToDate = (timestamp: number | string) => {
  const timestampMilliseconds = +`${timestamp}`.padEnd(13, "0");

  return new Date(timestampMilliseconds);
};

export const getStartOfMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), 1);

export const getEndOfMonth = (date: Date) =>
  new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );

export const getDateWithOffset = (
  date: Date,
  {
    years = 0,
    months = 0,
    weeks = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
    milliseconds = 0,
  }: {
    years?: number;
    months?: number;
    weeks?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
  },
) =>
  new Date(
    date.getFullYear() + years,
    date.getMonth() + months,
    date.getDate() + weeks * 7 + days,
    date.getHours() + hours,
    date.getMinutes() + minutes,
    date.getSeconds() + seconds,
    date.getMilliseconds() + milliseconds,
  );

export const getDaysInMonth = (date: Date): number => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return new Date(year, month, 0).getDate();
};

export const convertTimestamp = (
  timestamp: number,
  dateFormat: string,
  hourFormat: string,
): { dateString: string; timeString: string } => {
  const date = dayjs.unix(timestamp);
  const dateString = date.format(dateFormat);
  const timeString = date.format(hourFormat);

  return { dateString, timeString };
};

export const formatUnixTimestampToDate = (
  timestamp?: number,
  format = "DD/MM/YYYY",
): string | null => {
  if (!timestamp) {
    return null;
  }

  return dayjs.unix(timestamp).format(format);
};
