import { useTheme } from "@mui/material";
import {
  Button,
  Download,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetMigrationReports } from "@/api/graphql/migration/hooks";
import { RoundedTopLeftContainer } from "@/components/containers/RoundedTopLeftContainer";

import { ErrorComponent } from "../shared/Error";
import { Loading } from "../shared/Loading";
import {
  useDownloadMigrationResults,
  useGetMigrationReportsColumns,
  useGetMigrationReportsTable,
  useHandleLoadAccounts,
} from "./hooks";
import {
  StyledCountText,
  StyledCustomTable,
  StyledMigrationReportContainer,
  StyledMigrationReportsCount,
  StyledMigrationReportTextAreaContainer,
  StyledPageHeader,
  StyledSearchButton,
  StyledTextArea,
  StyledTextAreaContainer,
} from "./styles";
import { MigrationReportSuccessComponent } from "./Success";

export const MigrationReport = () => {
  const theme = useTheme();
  const { t: tCommon } = useTranslation("common");
  const { t: tMigrationReport } = useTranslation("migrationReport");
  const [accountsInput, setAccountsInput] = useState<string>("");
  const [accounts, setAccounts] = useState<string[] | undefined>();
  const { data, isLoading, isError, refetch } = useGetMigrationReports({
    accountNumbers: accounts,
  });
  const { columns } = useGetMigrationReportsColumns();
  const { rows: successfulRows } = useGetMigrationReportsTable({
    migrations: data?.successfulMigrations,
  });
  const { rows: failedRows } = useGetMigrationReportsTable({
    migrations: data?.failedMigrations,
  });

  const { handleLoadAccounts } = useHandleLoadAccounts(setAccounts);
  const { downloadMigrationResults } = useDownloadMigrationResults();
  const breadcrumbItems = [
    {
      title: tCommon("common.features.dashboard"),
      path: "/" as const,
    },
    {
      title: tCommon("common.sidebar.migrationReport"),
      path: "/backoffice-users" as const,
    },
  ];
  const diagnosedAccounts = data?.diagnosedAccounts || 0;
  const migratedAccounts = data?.migratedAccounts || 0;
  const isSuccess = useMemo(
    () =>
      diagnosedAccounts === migratedAccounts &&
      diagnosedAccounts !== 0 &&
      migratedAccounts !== 0 &&
      !!data?.successfulMigrations.length,
    [diagnosedAccounts, migratedAccounts, data?.successfulMigrations.length],
  );

  return (
    <>
      <StyledPageHeader
        breadcrumbItems={breadcrumbItems}
        title={tCommon("common.sidebar.migrationReport")}
        href=''
      />
      <RoundedTopLeftContainer>
        <StyledMigrationReportContainer>
          <StyledMigrationReportTextAreaContainer>
            <StyledTextAreaContainer>
              <StyledTextArea
                multiline
                maxRows={2}
                placeholder={tMigrationReport(
                  "migrationReport.inputPlaceholder",
                )}
                value={accountsInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAccountsInput(e.target.value);
                }}
              />
            </StyledTextAreaContainer>

            <StyledSearchButton>
              <Button
                variant='primary'
                isLowercase
                buttonText={tMigrationReport(
                  "migrationReport.actions.searchButton",
                )}
                onClick={() => handleLoadAccounts(accountsInput)}
              />
              <Button
                variant='secondary'
                isLowercase
                buttonText={tMigrationReport(
                  "migrationReport.actions.resetButton",
                )}
                onClick={() => {
                  setAccounts(undefined);
                  setAccounts(undefined);
                  setAccountsInput("");
                }}
              />
              <Button
                variant='secondary'
                isLowercase
                buttonText={tMigrationReport("migrationReport.actions.refresh")}
                onClick={() => refetch()}
              />
              <Button
                variant='secondary'
                isLowercase
                buttonText={tMigrationReport(
                  "migrationReport.actions.downloadSuccesses",
                )}
                onClick={() =>
                  downloadMigrationResults(data?.successfulMigrations)
                }
                disabled={!data?.successfulMigrations.length}
                leftIcon={
                  <Download
                    width={23}
                    height={23}
                    color={theme.palette.common.white}
                  />
                }
              />
              <Button
                variant='secondary'
                isLowercase
                buttonText={tMigrationReport(
                  "migrationReport.actions.downloadFailures",
                )}
                onClick={() => downloadMigrationResults(data?.failedMigrations)}
                disabled={!data?.failedMigrations.length}
                leftIcon={
                  <Download
                    width={23}
                    height={23}
                    color={theme.palette.common.white}
                  />
                }
              />
            </StyledSearchButton>
          </StyledMigrationReportTextAreaContainer>

          <StyledMigrationReportsCount>
            {diagnosedAccounts > 0 && (
              <StyledCountText
                variant='title1'
                sx={{ color: theme.palette.grey[700] }}
              >
                {tMigrationReport("migrationReport.diagnosedAccounts.count", {
                  count: diagnosedAccounts,
                })}
              </StyledCountText>
            )}
            {!!data?.successfulMigrations.length &&
              data?.successfulMigrations.length > 0 && (
                <StyledCountText
                  variant='title1'
                  sx={{ color: theme.palette.secondary.green[400] }}
                >
                  {tMigrationReport("migrationReport.migratedAccounts.count", {
                    count: data?.successfulMigrations.length,
                  })}
                </StyledCountText>
              )}
            {!!data?.failedMigrations.length &&
              data?.failedMigrations.length > 0 && (
                <StyledCountText
                  variant='title1'
                  sx={{ color: theme.palette.secondary.red[300] }}
                >
                  {tMigrationReport("migrationReport.unmigratedAccount.count", {
                    count: data?.failedMigrations.length,
                  })}
                </StyledCountText>
              )}
          </StyledMigrationReportsCount>
          <Loading
            isLoading={isLoading}
            secondaryText={tMigrationReport(
              "migrationReport.loading.secondaryText",
            )}
            principalText={tMigrationReport(
              "migrationReport.loading.principalText",
            )}
          >
            <ErrorComponent
              isError={isError}
              principalText={tMigrationReport(
                "migrationReport.errorMessage.principalText",
              )}
              secondaryText={tMigrationReport(
                "migrationReport.errorMessage.secondaryText",
              )}
            >
              <MigrationReportSuccessComponent isSuccess={isSuccess}>
                <>
                  {Boolean(failedRows.length) && (
                    <StyledCustomTable rows={failedRows} columns={columns} />
                  )}
                  {Boolean(successfulRows.length) && (
                    <StyledCustomTable
                      rows={successfulRows}
                      columns={columns}
                    />
                  )}
                </>
              </MigrationReportSuccessComponent>
            </ErrorComponent>
          </Loading>
        </StyledMigrationReportContainer>
      </RoundedTopLeftContainer>
    </>
  );
};
