import {
  BubbleText,
  CustomTableProps,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MigrationResult } from "@/api/graphql/migration/types";
import { useDownloadFile } from "@/hooks/download/hooks";

import {
  MAP_COLUMN_KEYS_TO_TRANSLATION_KEYS,
  MIGRATION_REPORT_COLUMN_KEYS,
  STATUS_COLORS,
  STATUS_LABELS,
} from "./constants";
import { useGetMigrationReportsTableInput } from "./types";

export const useGetMigrationReportsColumns = () => {
  const { t: tMigrationReport } = useTranslation("migrationReport");
  const columns: CustomTableProps["columns"] = useMemo(
    () =>
      MIGRATION_REPORT_COLUMN_KEYS.map((key) => ({
        columnName: tMigrationReport(MAP_COLUMN_KEYS_TO_TRANSLATION_KEYS[key]),
        displayedByDefault: true,
        dataTestId: `migrationReport__account_column-${key}`,
        cellWidth: "300px",
      })),
    [tMigrationReport],
  );

  return { columns };
};

export const useGetMigrationReportsTable = ({
  migrations,
}: useGetMigrationReportsTableInput) => {
  const { t: tMigrationReport } = useTranslation("migrationReport");

  const rows: CustomTableProps["rows"] = useMemo(() => {
    if (!migrations || !migrations.length) return [];

    const renderBubbleText = (value: string) => (
      <BubbleText
        variant={STATUS_COLORS[value]}
        text={tMigrationReport(STATUS_LABELS[value])}
      />
    );

    return migrations.map((result, index) => ({
      data: Object.fromEntries(
        MIGRATION_REPORT_COLUMN_KEYS.filter((key) => key in result).map(
          (key) => [
            key,
            key === "accountNumber" ? (
              <span>{result[key]}</span>
            ) : (
              renderBubbleText(result[key])
            ),
          ],
        ),
      ),
      key: `migration-report-row-${index}`,
    }));
  }, [tMigrationReport, migrations]);

  return {
    rows,
  };
};

export const useHandleLoadAccounts = (
  setAccounts: (value: string[]) => void,
) => {
  const handleLoadAccounts = (accountsInput: string) => {
    const inputAccounts = accountsInput
      .split("\n")
      .map((acc) => acc.trim())
      .filter(Boolean);

    setAccounts(inputAccounts);
  };

  return { handleLoadAccounts };
};

export const useDownloadMigrationResults = () => {
  const { t: tMigrationReport } = useTranslation("migrationReport");
  const { downloadFile } = useDownloadFile("Migration-reports");
  const exportedMigrationResults = useCallback(
    (migrationResults: Array<MigrationResult> | undefined) =>
      migrationResults?.map((entry) =>
        Object.fromEntries(
          Object.entries(entry).map(([key, value]) => [
            tMigrationReport(
              MAP_COLUMN_KEYS_TO_TRANSLATION_KEYS[key as keyof MigrationResult],
            ),
            STATUS_LABELS[value]
              ? tMigrationReport(STATUS_LABELS[value])
              : value,
          ]),
        ),
      ) || [],
    [tMigrationReport],
  );
  const downloadMigrationResults = useCallback(
    (migrationResults: Array<MigrationResult> | undefined) =>
      downloadFile(exportedMigrationResults(migrationResults)),
    [exportedMigrationResults, downloadFile],
  );

  return { downloadMigrationResults };
};
