import ExcelJS from "exceljs";
import { useCallback } from "react";

import { WorksheetData } from "@/hooks/download/types.ts";

export const useDownloadFile = (fileName: string) => {
  const downloadFile = useCallback(
    async (data: WorksheetData[]) => {
      if (!data.length) return;

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(fileName);
      const headers = Array.from(
        new Set(
          data.reduce<string[]>(
            (acc, row) => [...acc, ...Object.keys(row)],
            [],
          ),
        ),
      );

      worksheet.columns = headers.map((header) => ({
        header,
        key: header,
        width: 20,
      }));

      data.forEach((row) => {
        const worksheetRow: any = {};

        headers.forEach((header) => {
          worksheetRow[header] = row[header] ?? "";
        });
        worksheet.addRow(worksheetRow);
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "CCCCCC" },
        };
      });

      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) return;
        row.eachCell((cell) => {
          if (cell.value === "Oui") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "E54928" },
            };
          } else if (cell.value === "Non") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "55CCA7" },
            };
          }
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `${fileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    [fileName],
  );

  return { downloadFile };
};
