import { useQuery } from "@tanstack/react-query";

import { queries } from "@/api/graphql/constants.ts";
import { fromApiFormDataRuleGroupsToFeFormDataRuleGroups } from "@/api/graphql/formDataInjector/getSearchFormDataRuleGroups/mapper.ts";

import { getSearchFormDataRuleGroups } from "./api.ts";

export const useGetSearchFormDataRuleGroupsQuery = (ids?: string[]) => {
  const response = useQuery({
    queryKey: [queries.GET_SEARCH_FORM_DATA_RULE_GROUPS],
    queryFn: async () =>
      await getSearchFormDataRuleGroups({
        filters: { ids },
      }),
  });

  const data = fromApiFormDataRuleGroupsToFeFormDataRuleGroups(response.data);

  return {
    data,
    isLoading: response.isLoading,
  };
};
