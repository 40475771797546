import { yaml } from "@codemirror/lang-yaml";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DEFAULT_YAML,
  YAML_SIZE,
  YamlConfig,
} from "@/components/shared/YamlEditor/constants";
import {
  StyledError,
  StyledValid,
  StyledYamlEditorContainer,
} from "@/components/shared/YamlEditor/style";
import { YamlEditorProps } from "@/components/shared/YamlEditor/types";
import { validateYaml } from "@/helpers/validateYaml";

export const YamlEditor: React.FC<YamlEditorProps> = ({
  value = DEFAULT_YAML,
  defaultValue = DEFAULT_YAML,
  onChange,
  setError,
  height = YAML_SIZE,
  dataTestId,
}) => {
  const [localError, setLocalError] = useState<string | null>(null);
  const { t: tFormDataInjector } = useTranslation("formDataInjector");

  const handleChange = (newValue: string) => {
    onChange(newValue);
    validateYaml({ setLocalError, setError, yamlContent: newValue });
  };

  return (
    <StyledYamlEditorContainer data-testid={dataTestId}>
      <CodeMirror
        value={value}
        extensions={[yaml(), ...YamlConfig]}
        theme={githubLight}
        defaultValue={defaultValue}
        height={height}
        basicSetup={{
          lineNumbers: true,
          highlightActiveLine: true,
          highlightActiveLineGutter: true,
          foldGutter: true,
        }}
        onChange={handleChange}
      />

      {localError ? (
        <StyledError>❌ {localError}</StyledError>
      ) : (
        <StyledValid>
          ✅ {tFormDataInjector("formDataInjector.yamlEditor.valid")}
        </StyledValid>
      )}
    </StyledYamlEditorContainer>
  );
};
