import {
  BubbleText,
  BubbleTextProps,
  CustomTableProps,
  RowData,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { MigrationStatusData } from "@/api/graphql/migration/status/types";

import { MIGRATION_STATUS_COLUMNS } from "./constants";
import { MigrationStatusFilter } from "./types";
import { getMigrationStatusBubbleProps } from "./utils";

export const useGetMigrationStatusTable = (
  data: MigrationStatusData[] | undefined,
) => {
  const { t: tMigrationStatus } = useTranslation("migrationStatus");

  if (!data) {
    return {
      columns: [],
      rows: [],
    };
  }

  const columns: CustomTableProps["columns"] = MIGRATION_STATUS_COLUMNS.map(
    (key) => ({
      columnName: tMigrationStatus(`migrationStatus.list.${key}`),
      dataTestId: `migration-status-list-${key}-column-header`,
      displayedByDefault: true,
    }),
  );

  const rows: CustomTableProps["rows"] = data.reduce<Array<RowData>>(
    (acc, row, index) => {
      const migrationStatus = getMigrationStatusBubbleProps(row.status);

      const item: RowData = {
        contextMenuDataTestId: `migration-status-list-context-menu-${index}`,
        data: {
          code: (
            <div data-testid={`migration-status-list-code-${index}`}>
              {row.code}
            </div>
          ),
          status: (
            <div data-testid={`migration-status-list-status-${index}`}>
              <BubbleText
                text={tMigrationStatus(migrationStatus.translationKey)}
                variant={migrationStatus.variant as BubbleTextProps["variant"]}
              />
            </div>
          ),
          isEmpty: (
            <div data-testid={`migration-status-list-is-empty-${index}`}>
              {tMigrationStatus(
                row.isEmpty
                  ? "migrationStatus.boolean.yes"
                  : "migrationStatus.boolean.no",
              )}
            </div>
          ),
          isMb1: (
            <div data-testid={`migration-status-list-is-mb1-${index}`}>
              {tMigrationStatus(
                row.isMb1
                  ? "migrationStatus.boolean.yes"
                  : "migrationStatus.boolean.no",
              )}
            </div>
          ),
          successfulTs: (
            <div data-testid={`migration-status-list-successful-ts-${index}`}>
              {row.successfulTs}
            </div>
          ),
        },
        key: `migration-status-list-key-${index}`,
        menuItems: undefined,
      };

      acc.push(item);

      return acc;
    },
    [],
  );

  return {
    columns,
    rows,
  };
};

export const useMigrationStatusFilter = (
  data: Array<MigrationStatusData> | undefined,
) => {
  const [selected, setSelected] = useState<MigrationStatusFilter>(
    MigrationStatusFilter.NONE,
  );

  const filtered = useMemo(() => {
    if (!data) return undefined;

    return selected === MigrationStatusFilter.FROM_MB1
      ? data.filter((item) => item.isMb1)
      : data;
  }, [data, selected]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as MigrationStatusFilter;
    setSelected(value);
  };

  return {
    filtered,
    onChange,
    selected,
  };
};
