import { useQuery } from "@tanstack/react-query";

import { queries } from "@/api/graphql/constants.ts";

import { getGlobalSearch } from "./api";
import { mapGlobalSearchResultFromGraphQL } from "./mappers";

export const useGlobalSearch = (terms: string) => {
  const response = useQuery({
    queryKey: [queries.GET_GLOBAL_SEARCH, terms],
    enabled: terms.length > 2,
    queryFn: async () => await getGlobalSearch(terms),
  });

  return {
    data: response.data ? mapGlobalSearchResultFromGraphQL(response.data) : [],
    isLoading: response.isLoading,
    isError: response.isError,
    totalAccountResults:
      Number(response.data?.AccountsMsSearchAccounts?.pagination?.total) || 0,
    totalUsersResults:
      Number(response.data?.AccountsMsSearchUsers?.pagination?.total) || 0,
  };
};
