import { z } from "zod";
const agentType = ["AGENT", "SUPER_AGENT"] as const;

export const userFormSchema = z.object({
  firstName: z
    .string()
    .trim()
    .min(1, "users.forms.errors.required")
    .max(50, "users.forms.errors.maxLength"),
  lastName: z
    .string({
      required_error: "users.forms.errors.required",
    })
    .trim()
    .min(1, "users.forms.errors.required")
    .max(50, "users.forms.errors.maxLength"),
  iGG: z
    .string({
      required_error: "users.forms.errors.required",
    })
    .trim()
    .regex(/^[A-Za-z0-9]+$/, "users.forms.errors.alphaNumeric"),
  email: z
    .string({
      required_error: "users.forms.errors.required",
    })
    .trim()
    .email("users.forms.errors.email"),
  jobTitle: z.string().trim().optional().nullable(),
  profile: z.enum(agentType, {
    required_error: "users.forms.errors.required",
    invalid_type_error: "users.forms.errors.required",
  }),
  rights: z.object({
    impersonate: z.boolean().optional(),
  }).optional(),
});

export type UserFormType = z.infer<typeof userFormSchema>;
