import { getGraphqlClient } from "@/api/graphql/client.ts";
import { GetMiddleMsBuildAccountsMigrationReportsQuery } from "@/api/graphql/types/graphql.ts";

import { getMiddleMsBuildAccountsMigrationReportsQuery } from "./queries";
import { GetMigrationReportsParams } from "./types";

export const getMigrationReports = async ({
  accountNumbers,
}: GetMigrationReportsParams) =>
  await getGraphqlClient().request<GetMiddleMsBuildAccountsMigrationReportsQuery>(
    getMiddleMsBuildAccountsMigrationReportsQuery,
    {
      request: {
        accountNumbers: accountNumbers,
      },
    },
  );
