import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { useAddSnackbar } from "@/contexts/snackbar";
import { useAbilities } from "@/hooks/ability/hooks";
import { useDisableSupportUser } from "@/hooks/agent/disable";
import { useEnableSupportUser } from "@/hooks/agent/enable";
import { useCancelSupportUserInvite } from "@/hooks/agent/useCancelSupportUserInvite";
import { FeSupportUser } from "@/types/agents";

export const useAgentLinks = ({
  setSelectedAgent,
  refetch,
  refetchCountStatus,
}: {
  setSelectedAgent: (agent: FeSupportUser | null) => void;
  refetch: () => void;
  refetchCountStatus: () => void;
}) => {
  const { t: tUsers } = useTranslation("users");
  const addSnackbar = useAddSnackbar();
  const { can } = useAbilities();
  const {
    cancelSupportUserInvite,
    isPending: isPendingCancelSupportUserInvite,
  } = useCancelSupportUserInvite();
  const { disableSupportUser } = useDisableSupportUser();
  const { enableSupportUser } = useEnableSupportUser();
  const canManageAgents = can("SUPER_AGENT");

  return useCallback(
    ({ agent, index }: { agent: FeSupportUser; index: number }) =>
      match(agent.status)
        .with("SUPPORT_USER_ENABLED", () => [
          {
            text: tUsers("users.actions.edit"),
            dataTestId: `agents__agents-list-context-menu-update-${index}`,
            size: "s",
            textAlign: "left",
            className: "customMenuItem",
            onClick: () => {
              setSelectedAgent(agent);
            },
          },
          {
            text: tUsers("users.actions.deactivate"),
            dataTestId: `agents__agents-list-context-menu-deactivate-${index}`,
            size: "s",
            color: "red",
            textAlign: "left",
            onClick: () => {
              disableSupportUser(agent.id)
                .then(() => {
                  addSnackbar({
                    type: "success",
                    title: tUsers("users.actions.deactivated"),
                    message: "",
                    isAutoClose: true,
                  });
                })
                .catch(() => {
                  addSnackbar({
                    type: "error",
                    title: tUsers("users.actions.deactivated"),
                    message: "",
                    isAutoClose: true,
                  });
                })
                .finally(() => {
                  refetch();
                  refetchCountStatus();
                });
            },
          },
        ])
        .with("SUPPORT_USER_DISABLED", () => [
          {
            text: tUsers("users.actions.reactivate"),
            dataTestId: `agents__agents-list-context-menu-reactivate-${index}`,
            size: "s",
            textAlign: "left",
            onClick: () => {
              enableSupportUser(agent.id)
                .then(() => {
                  addSnackbar({
                    type: "success",
                    title: tUsers("users.actions.reactivated"),
                    message: "",
                    isAutoClose: true,
                  });
                })
                .catch(() => {
                  addSnackbar({
                    type: "error",
                    title: tUsers("users.actions.reactivated"),
                    message: "",
                    isAutoClose: true,
                  });
                })
                .finally(() => {
                  refetch();
                  refetchCountStatus();
                });
            },
          },
        ])
        .with("SUPPORT_USER_INVITING", () => [
          {
            text: tUsers("users.actions.edit"),
            dataTestId: `agents__agents-list-context-menu-disabled-update-${index}`,
            size: "s",
            textAlign: "left",
            color: "grey",
            isDisabled: true,
            className: "customMenuItem",
            onClick: () => {
              setSelectedAgent(agent);
            },
          },
          {
            text: tUsers("users.actions.cancelInvitation.label"),
            dataTestId: `agents__agents-list-context-menu-cancel-invitation-${index}`,
            size: "s",
            textAlign: "left",
            color: canManageAgents ? "red" : "grey",
            isDisabled: !canManageAgents,
            isLoading: isPendingCancelSupportUserInvite,
            onClick: () => {
              cancelSupportUserInvite(agent.id)
                .then(() => {
                  addSnackbar({
                    type: "success",
                    title: tUsers("users.actions.cancelInvitation.success"),
                    message: tUsers(
                      "users.actions.cancelInvitation.successDescription",
                      {
                        email: agent.email,
                      },
                    ),
                    isAutoClose: true,
                  });
                })
                .catch(() => {
                  addSnackbar({
                    type: "error",
                    title: tUsers("users.actions.cancelInvitation.error"),
                    message: tUsers(
                      "users.actions.cancelInvitation.errorDescription",
                      {
                        email: agent.email,
                      },
                    ),
                    isAutoClose: true,
                  });
                })
                .finally(() => {
                  refetch();
                  refetchCountStatus();
                });
            },
          },
        ])
        .otherwise(() => []),
    [
      canManageAgents,
      cancelSupportUserInvite,
      isPendingCancelSupportUserInvite,
      tUsers,
      setSelectedAgent,
      addSnackbar,
      disableSupportUser,
      enableSupportUser,
      refetch,
      refetchCountStatus,
    ],
  );
};
