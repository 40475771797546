import { useInfiniteQuery } from "@tanstack/react-query";

import { queries } from "@/api/graphql/constants.ts";
import { getNotificationsByUser } from "@/api/graphql/notifications/api.ts";
import { GetNotificationsByUser } from "@/api/graphql/notifications/types.ts";
import { NotificationsMsSearchNotificationsRequestSortField } from "@/api/graphql/types/graphql.ts";
import { getNextPageParam } from "@/helpers/api/getNextPageParam";

export const useGetNotificationsByUser = ({
  pageSize,
  userId,
}: GetNotificationsByUser) => {
  const response = useInfiniteQuery({
    queryKey: [queries.GET_NOTIFICATIONS_BY_USER, userId],
    queryFn: async ({ pageParam = 1 }) => {
      try {
        return getNotificationsByUser({
          filters: { userId: userId },
          paging: {
            page: pageParam,
            size: pageSize,
          },
          sorts: {
            field: NotificationsMsSearchNotificationsRequestSortField.CreatedTs,
            ascending: false,
          },
        });
      } catch {
        return;
      }
    },
    getNextPageParam: (lastPage, allPages) =>
      getNextPageParam({
        lastPage,
        allPages,
        key: "NotificationsMsSearchNotifications",
      }),
    initialPageParam: 1,
  });

  return {
    ...response,
    totalNotifications:
      response.data?.pages[0]?.NotificationsMsSearchNotifications.pagination
        .total || 0,
  };
};
