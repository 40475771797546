import { GlobalSearchQuery } from "@/api/graphql/types/graphql";

import { CompanyDataType, EntityType, UserDataType } from "./types";

export const mapGlobalSearchResultFromGraphQL = (
  data: GlobalSearchQuery,
): Array<CompanyDataType | UserDataType> => {
  const accounts =
    data?.AccountsMsSearchAccounts.data?.map(
      (account) =>
        ({
          id: account.id,
          type: EntityType.Company,
          number: account.number,
          name: account.name,
        }) as CompanyDataType,
    ) ?? [];

  const users =
    data?.AccountsMsSearchUsers.data?.map(
      (user) =>
        ({
          id: user.id,
          type: EntityType.User,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        }) as UserDataType,
    ) ?? [];

  return [...accounts, ...users];
};
