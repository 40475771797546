import { useRouter } from "@tanstack/react-router";
import { useState } from "react";

import { EntityType } from "@/api/graphql/globalSearch";

import { SearchResultOption } from "./types";

export const useSearchAutocomplete = () => {
  const router = useRouter();

  const [highlightedOption, setHighlightedOption] =
    useState<SearchResultOption | null>(null);

  const handleHighlightChange = (
    _: React.SyntheticEvent,
    option: SearchResultOption | null,
  ) => {
    if (option && option.id !== highlightedOption?.id) {
      setHighlightedOption(option ?? null);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && highlightedOption) {
      event.preventDefault();

      // Redirect to the highlighted link
      const url =
        highlightedOption.type === EntityType.User
          ? `/users/${highlightedOption.id}`
          : `/accounts/${highlightedOption.id}`;

      router.navigate({ to: url });
    }
  };

  return {
    handleHighlightChange,
    handleKeyDown,
    highlightedOption,
    setHighlightedOption,
  };
};
