import styled from "@emotion/styled";

export const StyledLoadingContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  gap: 16px;
`;
