import { graphql } from "@/api/graphql/types/gql.ts";

export const getMiddleMsGetIngestionSnapshotQuery = graphql(`
  query GetMiddleMsGetIngestionSnapshot {
    MiddleMsGetIngestionSnapshot {
      flows {
        code
        status
        isEmpty
        isMb1
        successfulTs
      }
    }
  }
`);
