import { getGraphqlClient } from "@/api/graphql/client.ts";
import { MiddleMsUpdateFormDataRuleGroupRequest } from "@/api/graphql/types/graphql.ts";

import { updateFormDataRuleGroupMutation } from "./mutations.ts";

export const updateFormDataRuleGroup = ({
  id,
  codes,
  description,
  disabledFor,
  enabled,
  enabledFor,
  name,
  rules,
}: MiddleMsUpdateFormDataRuleGroupRequest) => {
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request(updateFormDataRuleGroupMutation, {
    request: {
      id,
      codes,
      description,
      disabledFor,
      enabled,
      enabledFor,
      name,
      rules,
    },
  });
};
