import { useQuery } from "@tanstack/react-query";

import { queries } from "@/api/graphql/constants.ts";

import { getMigrationReports } from "./api";
import { mapMigrationReportsFromGraphQL } from "./mappers";
import {
  GetMigrationReportsParams,
  GetMigrationReportsResponse,
} from "./types";

export const useGetMigrationReports = ({
  accountNumbers,
}: GetMigrationReportsParams): GetMigrationReportsResponse => {
  const response = useQuery({
    queryKey: [queries.GET_MIGRATION_REPORT, accountNumbers],
    queryFn: async () =>
      getMigrationReports({
        accountNumbers,
      }),
    enabled: !!accountNumbers,
  });
  const data = response.data
    ? mapMigrationReportsFromGraphQL(response.data)
    : undefined;

  return {
    data,
    isLoading: response.isLoading,
    isError: response.isError,
    refetch: response.refetch,
  };
};
