import styled from "@emotion/styled";
import { TextField } from "@totalenergiescode/mobility-business-rev-design-system";

import { ControlledTextField } from "../shared/ControlledTextField";

export const StyledForm = styled("form")``;
export const StyledToggleContainer = styled("div")`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
`;
export const StyledInputsContainer = styled("div")``;
export const StyledInput = styled(ControlledTextField)``;
export const StyledFormDataInjectorContainer = styled("div")``;
export const StyledFormDataInjector = styled("div")``;
export const StyledRuleList = styled("div")``;

export const StyledRuleItem = styled("div", {
  shouldForwardProp: (prop) => !["isSelected"].includes(prop),
})<{ isSelected: boolean }>`
  font-family: "Roboto", sans-serif;
  min-height: 50px;
  padding: 10px;
  background: ${({ theme }) => theme.palette.white};
  border-radius: 8px;
  border: ${({ theme, isSelected }) =>
    isSelected
      ? `2px solid ${theme.palette.primary[300]}`
      : `1px solid ${theme.palette.grey[300]}`};
  cursor: pointer;
`;
export const StyledRuleHeader = styled("div")``;
export const StyledRuleName = styled("div")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const StyledRuleStatus = styled("div", {
  shouldForwardProp: (prop) => prop !== "isEnabled",
})<{ isEnabled: boolean }>`
  color: ${({ isEnabled, theme }) =>
    isEnabled
      ? theme.palette.danger.success[500]
      : theme.palette.danger.danger[500]};
`;
export const StyledRuleDescription = styled("div")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const StyledContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 34px;
  flex-shrink: 0;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.palette.grey[100]} 0%,
    ${({ theme }) => theme.palette.background.paper} 30%
  );
  border-top-left-radius: 40px;
`;

export const StyledRulesFinder = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > :first-child {
    width: 70%;
  }
`;

export const StyledSearchField = styled(TextField)`
  max-width: 700px;
  border-radius: 50px 50px 0px 50px;
  *.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.grey[200]};
    border-radius: 50px 50px 0px 50px;
    border-width: 1;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Example: add a box shadow when focused */
  }
  .MuiOutlinedInput-root {
    padding-left: 8px;
  }
  .MuiInputAdornment-root > div {
    padding: 8px;
  }
`;

export const StyledDataContainer = styled("div")`
  max-height: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;

  ${StyledRuleList} {
    max-height: 600px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 10px;
    gap: 10px;

    ${StyledRuleItem} {
      ${StyledRuleHeader} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        ${StyledRuleName} {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  ${StyledFormDataInjectorContainer} {
    width: 100%;
    height: 100%;

    ${StyledInputsContainer} {
      display: flex;
      align-items: center;
      gap: 20px;
      mn-height: 50px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    ${StyledFormDataInjector} {
      box-sizing: border-box;
      background: ${({ theme }) => theme.palette.white};
      border: 1px solid ${({ theme }) => theme.palette.grey[300]};
      border-radius: 8px;
      padding: 20px;
      width: 100%;
      height: 100%;
      min-height: 500px;
    }
  }
`;

export const StyledButtonsContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 24px;
`;

export const StyledEmptyRule = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  padding: 10px;
  background: ${({ theme }) => theme.palette.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  gap: 10px;
  margin-top: 10px;
`;
