import { useTheme } from "@mui/material";
import {
  CircularProgress,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { RFC } from "react";

import { StyledLoadingContainer } from "@/components/shared/Loading/styles";

import { Props } from "./types";

export const Loading: RFC<Props> = ({
  children,
  isLoading,
  principalText,
  secondaryText,
}) => {
  const theme = useTheme();

  return isLoading ? (
    <StyledLoadingContainer>
      <CircularProgress dataTestId='loader' />
      {principalText && <Text variant='title2'>{principalText}</Text>}
      {secondaryText && (
        <Text variant='text2' sx={{ color: theme.palette.grey[500] }}>
          {secondaryText}
        </Text>
      )}
    </StyledLoadingContainer>
  ) : (
    children
  );
};
