import { deleteFormDataRuleGroup } from "@/api/graphql/formDataInjector/deleteFormDataRuleGroup/api";
import { duplicateFormDataRuleGroup } from "@/api/graphql/formDataInjector/duplicateFormDataRuleGroup/api";
import { toggleFormDataRuleGroup } from "@/api/graphql/formDataInjector/toggleFormDataRuleGroup/api";
import { updateFormDataRuleGroup } from "@/api/graphql/formDataInjector/updateFormDataRuleGroup/api";

export const MAX_LENGTH_FIELDS = 50;
export const MIN_LENGTH_FIELDS = 1;

export const ACTIONS = {
  toggle: toggleFormDataRuleGroup,
  delete: deleteFormDataRuleGroup,
  duplicate: duplicateFormDataRuleGroup,
  update: updateFormDataRuleGroup,
};
