import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

import { FormDataInjector as FormDataInjectorComponent } from "@/components/formDataInjector";
import { Loading } from "@/components/shared/Loading";
import { useAbilities } from "@/hooks/ability/hooks";

const FormDataInjector = () => {
  const { cannot, isLoading } = useAbilities();
  const router = useNavigate();

  useEffect(() => {
    if (!isLoading && cannot("MAINTAINER")) {
      router({ to: "/" });
    }
  }, [cannot, isLoading, router]);

  return (
    <Loading isLoading={isLoading}>
      <FormDataInjectorComponent />
    </Loading>
  );
};

export const Route = createFileRoute("/formdata-injector/")({
  component: () => <FormDataInjector />,
});
