import { styled } from "@mui/system";
import {
  CustomTable,
  Divider,
  Select,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { PageHeader } from "../shared/PageHeader";

export const StyledContainer = styled("div")``;
export const StyledFiltersContainer = styled("div")``;
export const StyledSelect = styled(Select)`
  width: 300px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  margin-left: ${({ theme }) => theme.spacing(3)};
`;

export const StyledCustomTable = styled(CustomTable)`
  thead {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }

  tbody {
    background-color: ${(props) => props.theme.palette.background.paper};
  }
`;

export const StyledPageHeader = styled(PageHeader)`
  justify-content: space-between;
  padding: 0 40px 20px 40px;
`;

export const StyledCurrentCountOnTotalText = styled(Text)`
  color: ${({ theme }) => theme.palette.grey[500]};
  text-align: center;
`;

export const StyledCurrentCountOnTotalContainer = styled("div")`
  padding-block: 14px;
`;
