import { GetFormDataRuleGroupsQuery } from "@/api/graphql/types/graphql.ts";

import { FormDataRuleGroupsFE } from "./types.ts";

export const fromApiFormDataRuleGroupsToFeFormDataRuleGroups = (
  queryResponse?: GetFormDataRuleGroupsQuery,
): Array<FormDataRuleGroupsFE> =>
  queryResponse?.MiddleMsSearchFormDataRuleGroups.data?.map((item) => ({
    id: item?.id || "",
    name: item?.name || "",
    description: item?.description || "",
    numberOfRules: item?.numberOfRules || 0,
    rules: item?.rules || "",
    enabled: item?.enabled || false,
    enabledFor: item?.enabledFor || [],
    disabledFor: item?.disabledFor || [],
    codes: item?.codes || [],
  })) || [];
