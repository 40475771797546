export type FeSupportUserProfile = "AGENT" | "PROFILE_UNKNOWN" | "SUPER_AGENT";

export type FeSupportUserStatus =
  | "STATUS_UNKNOWN"
  | "SUPPORT_USER_DISABLED"
  | "SUPPORT_USER_ENABLED"
  | "SUPPORT_USER_INVITING";

export type FeSupportUser = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  igg?: string;
  jobTitle?: string;
  accountProfile: FeSupportUserProfile;
  status: FeSupportUserStatus;
  createdTs: number | string;
  updatedAt?: number | string | undefined;
  hasImpersonateRight?: boolean;
};

export const DEFAULT_SORTABLE_SUPPORT_USER_FIELDS: (keyof FeSupportUser)[] = [
  "id",
];

export type FeSupportUserCountByStatus = {
  all: number;
  enabled: number;
  disabled: number;
  inviting: number;
};
