import { z } from "zod";

import { MiddleMsCreateFormDataRuleGroupRequestType } from "@/api/graphql/types/graphql";

export const CreationFormRuleSchema = z.object({
  codes: z.array(z.string()).optional(),
  description: z.string().optional(),
  enabled: z.boolean().optional(),
  name: z.string(),
  rules: z.string(),
  type: z.enum([
    MiddleMsCreateFormDataRuleGroupRequestType.FormRuleTypeCreationOrModification,
    MiddleMsCreateFormDataRuleGroupRequestType.FormRuleTypeCopy,
  ]),
});

export type CreationFormRuleType = z.infer<typeof CreationFormRuleSchema>;
