/* eslint-disable */
import * as types from "./graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  query GetAccountDetail($request: AccountsMsSearchAccountsRequest!) {\n    AccountsMsSearchAccounts(request: $request) {\n      data {\n        name\n        zipCode\n        city\n        country\n        number\n        address\n        MainPrivilegeIdData {\n          UserIdData {\n            firstName\n            lastName\n            email\n          }\n        }\n      }\n    }\n  }\n":
    types.GetAccountDetailDocument,
  "\n  query GetAccountUsers($request: AccountsMsSearchPrivilegesRequest) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        lastUsedTs\n        status\n        UserIdData {\n          id\n          firstName\n          lastName\n          email\n          createdTs\n        }\n        Subscriptions {\n          RolesProfileIdData {\n            name\n            code\n          }\n        }\n      }\n      pagination {\n        page\n        size\n        total\n      }\n    }\n  }\n":
    types.GetAccountUsersDocument,
  "\n  query GetAccount($request: AccountsMsSearchAccountsRequest!) {\n    AccountsMsSearchAccounts(request: $request) {\n      data {\n        id\n        number\n        name\n        registrationNumber\n        registrationName\n        country\n        city\n        zipCode\n        address\n        createdTs\n        MainPrivilegeIdData {\n          UserIdData {\n            email\n            id\n            firstName\n            lastName\n          }\n          id\n        }\n      }\n    }\n  }\n":
    types.GetAccountDocument,
  "\n  query GetUser($request: AccountsMsSearchUsersRequest!) {\n    AccountsMsSearchUsers(request: $request) {\n      data {\n        id\n        email\n        locale\n        timezone\n        firstName\n        lastName\n        phone\n        phoneAlt\n        jobTitle\n      }\n    }\n  }\n":
    types.GetUserDocument,
  "\n  query AccountsMsSearchPrivileges(\n    $request: AccountsMsSearchPrivilegesRequest\n  ) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        roles\n        rolesInvalidatedTs\n        disabledTs\n        lastUsedTs\n        invitedTs\n        enabledLastTs\n        userId\n        accountId\n        owner\n        status\n        AccountIdData {\n          id\n          name\n          number\n          registrationName\n          registrationNumber\n          isActive\n          createdTs\n          mainPrivilegeId\n        }\n        Subscriptions {\n          id\n          fromTs\n          tillTs\n          privilegeId\n          rolesProfileId\n          RolesProfileIdData {\n            id\n            name\n            roles\n            code\n            accountId\n            parentId\n            rootId\n            system\n          }\n        }\n      }\n    }\n  }\n":
    types.AccountsMsSearchPrivilegesDocument,
  "\n  mutation InviteAgent($request: AccountsMsInviteSupportUserRequest) {\n    AccountsMsInviteSupportUser(request: $request) {\n      id\n    }\n  }\n":
    types.InviteAgentDocument,
  "\n  query GetActionsByAccountQuery($request: AccountsMsSearchAuditLogsRequest) {\n    AccountsMsSearchAuditLogs(request: $request) {\n      data {\n        id\n        action\n        AgentIdData {\n          firstName\n          lastName\n          id\n          AccessIdData {\n            igg\n          }\n        }\n        UserIdData {\n          firstName\n          lastName\n        }\n        whenTs\n      }\n      pagination {\n        size\n        page\n        total\n      }\n    }\n  }\n":
    types.GetActionsByAccountQueryDocument,
  "\n  query GetActionsByUserQuery($request: AccountsMsSearchAuditLogsRequest) {\n    AccountsMsSearchAuditLogs(request: $request) {\n      data {\n        id\n        action\n        AgentIdData {\n          firstName\n          lastName\n          id\n          AccessIdData {\n            igg\n          }\n        }\n        whenTs\n      }\n      pagination {\n        size\n        page\n        total\n      }\n    }\n  }\n":
    types.GetActionsByUserQueryDocument,
  "\n  mutation CancelSupportAgentInvite($request: AccountsMsCancelSupportUserInviteRequest) {\n    AccountsMsCancelSupportUserInvite(request: $request)\n  }\n":
    types.CancelSupportAgentInviteDocument,
  "\n  mutation DisableAgent($request: AccountsMsDisableSupportUserRequest) {\n    AccountsMsDisableSupportUser(request: $request)\n  }\n":
    types.DisableAgentDocument,
  "\n  mutation EnableAgent($request: AccountsMsEnableSupportUserRequest) {\n    AccountsMsEnableSupportUser(request: $request)\n  }\n":
    types.EnableAgentDocument,
  "\n  query AccountsMsSearchSupportUsers(\n    $request: AccountsMsSearchSupportUsersRequest\n  ) {\n    AccountsMsSearchSupportUsers(request: $request) {\n      data {\n        id\n        accessId\n        rights\n        firstName\n        lastName\n        jobTitle\n        email\n        profile\n        status\n        invitedBy\n        createdTs\n        AccessIdData {\n          igg\n          lastAccessTs\n        }\n        status\n      }\n      pagination {\n        page\n        total\n        size\n      }\n    }\n  }\n":
    types.AccountsMsSearchSupportUsersDocument,
  "\n  query AccountsMsCountSupportUsersByStatus {\n    AccountsMsCountSupportUsersByStatus {\n      all\n      enabled\n      disabled\n      inviting\n    }\n  }\n":
    types.AccountsMsCountSupportUsersByStatusDocument,
  "\n  mutation UpdateAgent($request: AccountsMsUpdateSupportUserRequest) {\n    AccountsMsUpdateSupportUser(request: $request)\n  }\n":
    types.UpdateAgentDocument,
  "\n  query SearchAccountsMsMe($request: AccountsMsMeRequest) {\n    AccountsMsMe(request: $request) {\n      userId\n      sharedUserProfileTs\n      systemUserInfo\n      supportUserInfo {\n        igg\n        email\n        firstName\n        lastName\n        rights\n        subsidiaries\n      }\n    }\n  }\n":
    types.SearchAccountsMsMeDocument,
  "\n  mutation MiddleMsCreateFormDataRuleGroup(\n    $request: MiddleMsCreateFormDataRuleGroupRequest!\n  ) {\n    MiddleMsCreateFormDataRuleGroup(request: $request) {\n      id\n    }\n  }\n":
    types.MiddleMsCreateFormDataRuleGroupDocument,
  "\n  mutation DeleteFormDataRuleGroup($id: ID!) {\n    MiddleMsDeleteFormDataRuleGroup(request: { id: $id })\n  }\n":
    types.DeleteFormDataRuleGroupDocument,
  "\n  mutation DuplicateFormDataRuleGroup($id: ID!) {\n    MiddleMsDuplicateFormDataRuleGroup(request: { id: $id }) {\n      id\n    }\n  }\n":
    types.DuplicateFormDataRuleGroupDocument,
  "\n  query GetFormDataRuleGroups(\n    $request: MiddleMsSearchFormDataRuleGroupsRequest!\n  ) {\n    MiddleMsSearchFormDataRuleGroups(request: $request) {\n      data {\n        codes\n        description\n        disabledFor\n        enabled\n        enabledFor\n        id\n        name\n        numberOfRules\n        rules\n      }\n    }\n  }\n":
    types.GetFormDataRuleGroupsDocument,
  "\n  mutation ToggleFormDataRuleGroup($id: ID!) {\n    MiddleMsToggleFormDataRuleGroup(request: { id: $id }) {\n      enabled\n    }\n  }\n":
    types.ToggleFormDataRuleGroupDocument,
  "\n  mutation UpdateFormDataRuleGroup(\n    $request: MiddleMsUpdateFormDataRuleGroupRequest!\n  ) {\n    MiddleMsUpdateFormDataRuleGroup(request: $request)\n  }\n":
    types.UpdateFormDataRuleGroupDocument,
  "\n  query GlobalSearch(\n    $searchQuery: String!\n    $limitAccounts: Int!\n    $limitUsers: Int!\n  ) {\n    __typename\n    AccountsMsSearchAccounts(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitAccounts }\n      }\n    ) {\n      data {\n        id\n        number\n        name\n      }\n      pagination {\n        total\n      }\n    }\n    AccountsMsSearchUsers(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitUsers }\n      }\n    ) {\n      data {\n        id\n        email\n        firstName\n        lastName\n      }\n      pagination {\n        total\n      }\n    }\n  }\n":
    types.GlobalSearchDocument,
  "\n  mutation AccountsMsStartSupport($request: AccountsMsStartSupportRequest) {\n    AccountsMsStartSupport(request: $request) {\n      expiresTs\n      sessionId\n    }\n  }\n":
    types.AccountsMsStartSupportDocument,
  "\n  query GetMiddleMsBuildAccountsMigrationReports(\n    $request: MiddleMsBuildAccountsMigrationReportRequest\n  ) {\n    MiddleMsBuildAccountsMigrationReport(request: $request) {\n      migratedAccounts\n      diagnosedAccounts\n      failures {\n        accountNumber\n        contactEmailIsNull\n        accountNumberIsAct\n        accountHasNoPcom\n        billingAccountPayerCurrencyIsNull\n        contractOfferNameIsNull\n        contractOfferCodeIsNull\n        contactEmailIsInvalid\n        contractBillingFrequencyCodeIsNull\n        contractExternalContractIdIsNull\n        contractContractNumIsNull\n        contractIsNotMb2\n        contractHasNoScom\n        contractHasInvalidStatus\n        notPresentInAccountsService\n        contactIsNull\n        contractMissing\n      }\n      successes {\n        accountNumber\n        contactEmailIsNull\n        accountNumberIsAct\n        accountHasNoPcom\n        billingAccountPayerCurrencyIsNull\n        contractOfferNameIsNull\n        contractOfferCodeIsNull\n        contractBillingFrequencyCodeIsNull\n        contactEmailIsInvalid\n        contractExternalContractIdIsNull\n        contractContractNumIsNull\n        contractIsNotMb2\n        contractHasNoScom\n        contractHasInvalidStatus\n        notPresentInAccountsService\n        contactIsNull\n        contractMissing\n      }\n    }\n  }\n":
    types.GetMiddleMsBuildAccountsMigrationReportsDocument,
  "\n  query GetMiddleMsGetIngestionSnapshot {\n    MiddleMsGetIngestionSnapshot {\n      flows {\n        code\n        status\n        isEmpty\n        isMb1\n        successfulTs\n      }\n    }\n  }\n":
    types.GetMiddleMsGetIngestionSnapshotDocument,
  "\n  query GetNotificationsByUserQuery(\n    $request: NotificationsMsSearchNotificationsRequest\n  ) {\n    NotificationsMsSearchNotifications(request: $request) {\n      data {\n        id\n        subject\n        hasAttachments\n        createdTs\n        status\n        failedReason\n        AccountIdData {\n          name\n          number\n        }\n        UserIdData {\n          firstName\n          lastName\n          email\n        }\n      }\n      pagination {\n        size\n        page\n        total\n      }\n    }\n  }\n":
    types.GetNotificationsByUserQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetAccountDetail($request: AccountsMsSearchAccountsRequest!) {\n    AccountsMsSearchAccounts(request: $request) {\n      data {\n        name\n        zipCode\n        city\n        country\n        number\n        address\n        MainPrivilegeIdData {\n          UserIdData {\n            firstName\n            lastName\n            email\n          }\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetAccountDetail($request: AccountsMsSearchAccountsRequest!) {\n    AccountsMsSearchAccounts(request: $request) {\n      data {\n        name\n        zipCode\n        city\n        country\n        number\n        address\n        MainPrivilegeIdData {\n          UserIdData {\n            firstName\n            lastName\n            email\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetAccountUsers($request: AccountsMsSearchPrivilegesRequest) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        lastUsedTs\n        status\n        UserIdData {\n          id\n          firstName\n          lastName\n          email\n          createdTs\n        }\n        Subscriptions {\n          RolesProfileIdData {\n            name\n            code\n          }\n        }\n      }\n      pagination {\n        page\n        size\n        total\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetAccountUsers($request: AccountsMsSearchPrivilegesRequest) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        lastUsedTs\n        status\n        UserIdData {\n          id\n          firstName\n          lastName\n          email\n          createdTs\n        }\n        Subscriptions {\n          RolesProfileIdData {\n            name\n            code\n          }\n        }\n      }\n      pagination {\n        page\n        size\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetAccount($request: AccountsMsSearchAccountsRequest!) {\n    AccountsMsSearchAccounts(request: $request) {\n      data {\n        id\n        number\n        name\n        registrationNumber\n        registrationName\n        country\n        city\n        zipCode\n        address\n        createdTs\n        MainPrivilegeIdData {\n          UserIdData {\n            email\n            id\n            firstName\n            lastName\n          }\n          id\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetAccount($request: AccountsMsSearchAccountsRequest!) {\n    AccountsMsSearchAccounts(request: $request) {\n      data {\n        id\n        number\n        name\n        registrationNumber\n        registrationName\n        country\n        city\n        zipCode\n        address\n        createdTs\n        MainPrivilegeIdData {\n          UserIdData {\n            email\n            id\n            firstName\n            lastName\n          }\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetUser($request: AccountsMsSearchUsersRequest!) {\n    AccountsMsSearchUsers(request: $request) {\n      data {\n        id\n        email\n        locale\n        timezone\n        firstName\n        lastName\n        phone\n        phoneAlt\n        jobTitle\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetUser($request: AccountsMsSearchUsersRequest!) {\n    AccountsMsSearchUsers(request: $request) {\n      data {\n        id\n        email\n        locale\n        timezone\n        firstName\n        lastName\n        phone\n        phoneAlt\n        jobTitle\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query AccountsMsSearchPrivileges(\n    $request: AccountsMsSearchPrivilegesRequest\n  ) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        roles\n        rolesInvalidatedTs\n        disabledTs\n        lastUsedTs\n        invitedTs\n        enabledLastTs\n        userId\n        accountId\n        owner\n        status\n        AccountIdData {\n          id\n          name\n          number\n          registrationName\n          registrationNumber\n          isActive\n          createdTs\n          mainPrivilegeId\n        }\n        Subscriptions {\n          id\n          fromTs\n          tillTs\n          privilegeId\n          rolesProfileId\n          RolesProfileIdData {\n            id\n            name\n            roles\n            code\n            accountId\n            parentId\n            rootId\n            system\n          }\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  query AccountsMsSearchPrivileges(\n    $request: AccountsMsSearchPrivilegesRequest\n  ) {\n    AccountsMsSearchPrivileges(request: $request) {\n      data {\n        id\n        roles\n        rolesInvalidatedTs\n        disabledTs\n        lastUsedTs\n        invitedTs\n        enabledLastTs\n        userId\n        accountId\n        owner\n        status\n        AccountIdData {\n          id\n          name\n          number\n          registrationName\n          registrationNumber\n          isActive\n          createdTs\n          mainPrivilegeId\n        }\n        Subscriptions {\n          id\n          fromTs\n          tillTs\n          privilegeId\n          rolesProfileId\n          RolesProfileIdData {\n            id\n            name\n            roles\n            code\n            accountId\n            parentId\n            rootId\n            system\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation InviteAgent($request: AccountsMsInviteSupportUserRequest) {\n    AccountsMsInviteSupportUser(request: $request) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation InviteAgent($request: AccountsMsInviteSupportUserRequest) {\n    AccountsMsInviteSupportUser(request: $request) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetActionsByAccountQuery($request: AccountsMsSearchAuditLogsRequest) {\n    AccountsMsSearchAuditLogs(request: $request) {\n      data {\n        id\n        action\n        AgentIdData {\n          firstName\n          lastName\n          id\n          AccessIdData {\n            igg\n          }\n        }\n        UserIdData {\n          firstName\n          lastName\n        }\n        whenTs\n      }\n      pagination {\n        size\n        page\n        total\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetActionsByAccountQuery($request: AccountsMsSearchAuditLogsRequest) {\n    AccountsMsSearchAuditLogs(request: $request) {\n      data {\n        id\n        action\n        AgentIdData {\n          firstName\n          lastName\n          id\n          AccessIdData {\n            igg\n          }\n        }\n        UserIdData {\n          firstName\n          lastName\n        }\n        whenTs\n      }\n      pagination {\n        size\n        page\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetActionsByUserQuery($request: AccountsMsSearchAuditLogsRequest) {\n    AccountsMsSearchAuditLogs(request: $request) {\n      data {\n        id\n        action\n        AgentIdData {\n          firstName\n          lastName\n          id\n          AccessIdData {\n            igg\n          }\n        }\n        whenTs\n      }\n      pagination {\n        size\n        page\n        total\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetActionsByUserQuery($request: AccountsMsSearchAuditLogsRequest) {\n    AccountsMsSearchAuditLogs(request: $request) {\n      data {\n        id\n        action\n        AgentIdData {\n          firstName\n          lastName\n          id\n          AccessIdData {\n            igg\n          }\n        }\n        whenTs\n      }\n      pagination {\n        size\n        page\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation CancelSupportAgentInvite($request: AccountsMsCancelSupportUserInviteRequest) {\n    AccountsMsCancelSupportUserInvite(request: $request)\n  }\n",
): (typeof documents)["\n  mutation CancelSupportAgentInvite($request: AccountsMsCancelSupportUserInviteRequest) {\n    AccountsMsCancelSupportUserInvite(request: $request)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation DisableAgent($request: AccountsMsDisableSupportUserRequest) {\n    AccountsMsDisableSupportUser(request: $request)\n  }\n",
): (typeof documents)["\n  mutation DisableAgent($request: AccountsMsDisableSupportUserRequest) {\n    AccountsMsDisableSupportUser(request: $request)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation EnableAgent($request: AccountsMsEnableSupportUserRequest) {\n    AccountsMsEnableSupportUser(request: $request)\n  }\n",
): (typeof documents)["\n  mutation EnableAgent($request: AccountsMsEnableSupportUserRequest) {\n    AccountsMsEnableSupportUser(request: $request)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query AccountsMsSearchSupportUsers(\n    $request: AccountsMsSearchSupportUsersRequest\n  ) {\n    AccountsMsSearchSupportUsers(request: $request) {\n      data {\n        id\n        accessId\n        rights\n        firstName\n        lastName\n        jobTitle\n        email\n        profile\n        status\n        invitedBy\n        createdTs\n        AccessIdData {\n          igg\n          lastAccessTs\n        }\n        status\n      }\n      pagination {\n        page\n        total\n        size\n      }\n    }\n  }\n",
): (typeof documents)["\n  query AccountsMsSearchSupportUsers(\n    $request: AccountsMsSearchSupportUsersRequest\n  ) {\n    AccountsMsSearchSupportUsers(request: $request) {\n      data {\n        id\n        accessId\n        rights\n        firstName\n        lastName\n        jobTitle\n        email\n        profile\n        status\n        invitedBy\n        createdTs\n        AccessIdData {\n          igg\n          lastAccessTs\n        }\n        status\n      }\n      pagination {\n        page\n        total\n        size\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query AccountsMsCountSupportUsersByStatus {\n    AccountsMsCountSupportUsersByStatus {\n      all\n      enabled\n      disabled\n      inviting\n    }\n  }\n",
): (typeof documents)["\n  query AccountsMsCountSupportUsersByStatus {\n    AccountsMsCountSupportUsersByStatus {\n      all\n      enabled\n      disabled\n      inviting\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation UpdateAgent($request: AccountsMsUpdateSupportUserRequest) {\n    AccountsMsUpdateSupportUser(request: $request)\n  }\n",
): (typeof documents)["\n  mutation UpdateAgent($request: AccountsMsUpdateSupportUserRequest) {\n    AccountsMsUpdateSupportUser(request: $request)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query SearchAccountsMsMe($request: AccountsMsMeRequest) {\n    AccountsMsMe(request: $request) {\n      userId\n      sharedUserProfileTs\n      systemUserInfo\n      supportUserInfo {\n        igg\n        email\n        firstName\n        lastName\n        rights\n        subsidiaries\n      }\n    }\n  }\n",
): (typeof documents)["\n  query SearchAccountsMsMe($request: AccountsMsMeRequest) {\n    AccountsMsMe(request: $request) {\n      userId\n      sharedUserProfileTs\n      systemUserInfo\n      supportUserInfo {\n        igg\n        email\n        firstName\n        lastName\n        rights\n        subsidiaries\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation MiddleMsCreateFormDataRuleGroup(\n    $request: MiddleMsCreateFormDataRuleGroupRequest!\n  ) {\n    MiddleMsCreateFormDataRuleGroup(request: $request) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation MiddleMsCreateFormDataRuleGroup(\n    $request: MiddleMsCreateFormDataRuleGroupRequest!\n  ) {\n    MiddleMsCreateFormDataRuleGroup(request: $request) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation DeleteFormDataRuleGroup($id: ID!) {\n    MiddleMsDeleteFormDataRuleGroup(request: { id: $id })\n  }\n",
): (typeof documents)["\n  mutation DeleteFormDataRuleGroup($id: ID!) {\n    MiddleMsDeleteFormDataRuleGroup(request: { id: $id })\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation DuplicateFormDataRuleGroup($id: ID!) {\n    MiddleMsDuplicateFormDataRuleGroup(request: { id: $id }) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation DuplicateFormDataRuleGroup($id: ID!) {\n    MiddleMsDuplicateFormDataRuleGroup(request: { id: $id }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetFormDataRuleGroups(\n    $request: MiddleMsSearchFormDataRuleGroupsRequest!\n  ) {\n    MiddleMsSearchFormDataRuleGroups(request: $request) {\n      data {\n        codes\n        description\n        disabledFor\n        enabled\n        enabledFor\n        id\n        name\n        numberOfRules\n        rules\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetFormDataRuleGroups(\n    $request: MiddleMsSearchFormDataRuleGroupsRequest!\n  ) {\n    MiddleMsSearchFormDataRuleGroups(request: $request) {\n      data {\n        codes\n        description\n        disabledFor\n        enabled\n        enabledFor\n        id\n        name\n        numberOfRules\n        rules\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation ToggleFormDataRuleGroup($id: ID!) {\n    MiddleMsToggleFormDataRuleGroup(request: { id: $id }) {\n      enabled\n    }\n  }\n",
): (typeof documents)["\n  mutation ToggleFormDataRuleGroup($id: ID!) {\n    MiddleMsToggleFormDataRuleGroup(request: { id: $id }) {\n      enabled\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation UpdateFormDataRuleGroup(\n    $request: MiddleMsUpdateFormDataRuleGroupRequest!\n  ) {\n    MiddleMsUpdateFormDataRuleGroup(request: $request)\n  }\n",
): (typeof documents)["\n  mutation UpdateFormDataRuleGroup(\n    $request: MiddleMsUpdateFormDataRuleGroupRequest!\n  ) {\n    MiddleMsUpdateFormDataRuleGroup(request: $request)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GlobalSearch(\n    $searchQuery: String!\n    $limitAccounts: Int!\n    $limitUsers: Int!\n  ) {\n    __typename\n    AccountsMsSearchAccounts(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitAccounts }\n      }\n    ) {\n      data {\n        id\n        number\n        name\n      }\n      pagination {\n        total\n      }\n    }\n    AccountsMsSearchUsers(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitUsers }\n      }\n    ) {\n      data {\n        id\n        email\n        firstName\n        lastName\n      }\n      pagination {\n        total\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GlobalSearch(\n    $searchQuery: String!\n    $limitAccounts: Int!\n    $limitUsers: Int!\n  ) {\n    __typename\n    AccountsMsSearchAccounts(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitAccounts }\n      }\n    ) {\n      data {\n        id\n        number\n        name\n      }\n      pagination {\n        total\n      }\n    }\n    AccountsMsSearchUsers(\n      request: {\n        filters: { query: $searchQuery }\n        paging: { size: $limitUsers }\n      }\n    ) {\n      data {\n        id\n        email\n        firstName\n        lastName\n      }\n      pagination {\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation AccountsMsStartSupport($request: AccountsMsStartSupportRequest) {\n    AccountsMsStartSupport(request: $request) {\n      expiresTs\n      sessionId\n    }\n  }\n",
): (typeof documents)["\n  mutation AccountsMsStartSupport($request: AccountsMsStartSupportRequest) {\n    AccountsMsStartSupport(request: $request) {\n      expiresTs\n      sessionId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetMiddleMsBuildAccountsMigrationReports(\n    $request: MiddleMsBuildAccountsMigrationReportRequest\n  ) {\n    MiddleMsBuildAccountsMigrationReport(request: $request) {\n      migratedAccounts\n      diagnosedAccounts\n      failures {\n        accountNumber\n        contactEmailIsNull\n        accountNumberIsAct\n        accountHasNoPcom\n        billingAccountPayerCurrencyIsNull\n        contractOfferNameIsNull\n        contractOfferCodeIsNull\n        contactEmailIsInvalid\n        contractBillingFrequencyCodeIsNull\n        contractExternalContractIdIsNull\n        contractContractNumIsNull\n        contractIsNotMb2\n        contractHasNoScom\n        contractHasInvalidStatus\n        notPresentInAccountsService\n        contactIsNull\n        contractMissing\n      }\n      successes {\n        accountNumber\n        contactEmailIsNull\n        accountNumberIsAct\n        accountHasNoPcom\n        billingAccountPayerCurrencyIsNull\n        contractOfferNameIsNull\n        contractOfferCodeIsNull\n        contractBillingFrequencyCodeIsNull\n        contactEmailIsInvalid\n        contractExternalContractIdIsNull\n        contractContractNumIsNull\n        contractIsNotMb2\n        contractHasNoScom\n        contractHasInvalidStatus\n        notPresentInAccountsService\n        contactIsNull\n        contractMissing\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetMiddleMsBuildAccountsMigrationReports(\n    $request: MiddleMsBuildAccountsMigrationReportRequest\n  ) {\n    MiddleMsBuildAccountsMigrationReport(request: $request) {\n      migratedAccounts\n      diagnosedAccounts\n      failures {\n        accountNumber\n        contactEmailIsNull\n        accountNumberIsAct\n        accountHasNoPcom\n        billingAccountPayerCurrencyIsNull\n        contractOfferNameIsNull\n        contractOfferCodeIsNull\n        contactEmailIsInvalid\n        contractBillingFrequencyCodeIsNull\n        contractExternalContractIdIsNull\n        contractContractNumIsNull\n        contractIsNotMb2\n        contractHasNoScom\n        contractHasInvalidStatus\n        notPresentInAccountsService\n        contactIsNull\n        contractMissing\n      }\n      successes {\n        accountNumber\n        contactEmailIsNull\n        accountNumberIsAct\n        accountHasNoPcom\n        billingAccountPayerCurrencyIsNull\n        contractOfferNameIsNull\n        contractOfferCodeIsNull\n        contractBillingFrequencyCodeIsNull\n        contactEmailIsInvalid\n        contractExternalContractIdIsNull\n        contractContractNumIsNull\n        contractIsNotMb2\n        contractHasNoScom\n        contractHasInvalidStatus\n        notPresentInAccountsService\n        contactIsNull\n        contractMissing\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetMiddleMsGetIngestionSnapshot {\n    MiddleMsGetIngestionSnapshot {\n      flows {\n        code\n        status\n        isEmpty\n        isMb1\n        successfulTs\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetMiddleMsGetIngestionSnapshot {\n    MiddleMsGetIngestionSnapshot {\n      flows {\n        code\n        status\n        isEmpty\n        isMb1\n        successfulTs\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetNotificationsByUserQuery(\n    $request: NotificationsMsSearchNotificationsRequest\n  ) {\n    NotificationsMsSearchNotifications(request: $request) {\n      data {\n        id\n        subject\n        hasAttachments\n        createdTs\n        status\n        failedReason\n        AccountIdData {\n          name\n          number\n        }\n        UserIdData {\n          firstName\n          lastName\n          email\n        }\n      }\n      pagination {\n        size\n        page\n        total\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetNotificationsByUserQuery(\n    $request: NotificationsMsSearchNotificationsRequest\n  ) {\n    NotificationsMsSearchNotifications(request: $request) {\n      data {\n        id\n        subject\n        hasAttachments\n        createdTs\n        status\n        failedReason\n        AccountIdData {\n          name\n          number\n        }\n        UserIdData {\n          firstName\n          lastName\n          email\n        }\n      }\n      pagination {\n        size\n        page\n        total\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
