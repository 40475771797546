import { capitalize } from "@mui/material";
import {
  BubbleText,
  ContextualMenu,
  Subtract,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { usePaginatedGetSearchAgents } from "@/api/graphql/agents/search";
import EditAgent from "@/components/Agents/Edit/component.tsx";
import {
  StyledButton,
  StyledButtonContainer,
  StyledCell,
  StyledCellContextualMenu,
  StyledCellHeaderText,
  StyledCellText,
  StyledCurrentCountOnTotalContainer,
  StyledCurrentCountOnTotalText,
  StyledCustomTableContainer,
  StyledRowContainerLink,
  StyledTable,
  StyledTableHeader,
} from "@/components/Agents/List";
import { useAgentLinks } from "@/components/Agents/List/hooks.ts";
import { getFilters } from "@/components/Agents/List/mappers.ts";
import { AgentsListProps } from "@/components/Agents/List/types.ts";
import { getStatus, getStatusLabel } from "@/components/BackOffice/helper.ts";
import { Loading } from "@/components/shared/Loading";
import { useFormattedDate } from "@/hooks/i18n/useFormatDate";
import { FeSupportUser } from "@/types/agents.ts";

import { AGENT_PROFILE_ICON } from "./constants";

export const AgentsListComponent = (props: AgentsListProps) => {
  const {
    data: listOfAgents,
    isLoading,
    totalOfDisplayableSupportUsersWithCurrentParameters,
    fetchNextPage,
    refetch,
  } = usePaginatedGetSearchAgents({
    filters: getFilters(props),
  });
  const { t: tUsers } = useTranslation("users");
  const { t: tCompanies } = useTranslation("companies");
  const { t: tAgents } = useTranslation("agents");
  const [menuOpen, setMenuOpen] = useState(-1);
  const [selectedAgent, setSelectedAgent] = useState<FeSupportUser | null>(
    null,
  );
  const formattedDate = useFormattedDate();
  const getLinks = useAgentLinks({
    setSelectedAgent,
    refetch,
    refetchCountStatus: props.refetchCountStatus,
  });

  //TODO: Using the DS CustomTable
  return (
    <>
      {selectedAgent && (
        <EditAgent
          isOpen={Boolean(selectedAgent)}
          onClose={() => setSelectedAgent(() => null)}
          agent={{
            id: selectedAgent!.id,
            firstName: selectedAgent?.firstName || "",
            lastName: selectedAgent?.lastName || "",

            email: selectedAgent?.email || "",
            iGG: selectedAgent?.igg || "",
            jobTitle: selectedAgent?.jobTitle || "",
            profile:
              selectedAgent?.accountProfile === "SUPER_AGENT"
                ? "SUPER_AGENT"
                : "AGENT",
            hasImpersonateRight: selectedAgent?.hasImpersonateRight || false,
          }}
        />
      )}
      <Loading isLoading={isLoading || false}>
        <>
          <StyledCustomTableContainer data-testid='agents__agents-list-fulfilled-container'>
            <StyledTableHeader>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.email")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.name")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>IGG</StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.accountProfile")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.status")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.createdTs")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.lastConnection")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {""}
                </StyledCellHeaderText>
              </StyledCell>
            </StyledTableHeader>

            <StyledTable>
              {(listOfAgents || []).map((agent, index) => (
                <StyledRowContainerLink key={agent.id}>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {agent.email}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {agent.firstName} {agent.lastName}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>{agent.igg}</StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    {AGENT_PROFILE_ICON[agent.accountProfile]}
                    <StyledCellText variant='text2'>
                      {/* TODO: use a mapper to have a formatted profile*/}
                      {tCompanies(
                        `companies.list.profile.${agent.accountProfile}`,
                      )}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      <BubbleText
                        text={capitalize(
                          tUsers(
                            `users.list.status.${getStatusLabel(agent.status)}.label`.toLowerCase(),
                          ),
                        )}
                        variant={getStatus(agent.status)}
                      />
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {formattedDate(agent.createdTs)}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {formattedDate(agent.updatedAt)}
                    </StyledCellText>
                  </StyledCell>

                  <StyledCellContextualMenu>
                    <Text variant='text2'>
                      <ContextualMenu
                        dataTestId={`agents__agents-list-context-menu-${index}`}
                        open={index === menuOpen}
                        handleClose={() => {
                          setMenuOpen(-1);
                        }}
                        handleOpen={() => {
                          setMenuOpen(index);
                        }}
                        links={getLinks({ agent, index })}
                      />
                    </Text>
                  </StyledCellContextualMenu>
                </StyledRowContainerLink>
              ))}
            </StyledTable>
          </StyledCustomTableContainer>
          {totalOfDisplayableSupportUsersWithCurrentParameters >
          (listOfAgents?.length || 0) ? (
            <StyledButtonContainer>
              <StyledButton
                leftIcon={<Subtract />}
                variant='secondary'
                buttonText={"Load more"}
                isLowercase={true}
                onClick={() =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  fetchNextPage()
                }
              ></StyledButton>
            </StyledButtonContainer>
          ) : null}
        </>
      </Loading>
      <StyledCurrentCountOnTotalContainer>
        <StyledCurrentCountOnTotalText variant='sub3'>
          {tAgents("agents.list.namesWithCurrentCountOnTotal.name", {
            count: listOfAgents?.length || 0,
            total: totalOfDisplayableSupportUsersWithCurrentParameters,
          })}
        </StyledCurrentCountOnTotalText>
      </StyledCurrentCountOnTotalContainer>
    </>
  );
};
