import { Box } from "@mui/system";
import { Checkbox } from "@totalenergiescode/mobility-business-rev-design-system";
import { Controller, FieldValues } from "react-hook-form";

import { type ControlledCheckboxProps } from "./types";

export const ControlledCheckbox = <T extends FieldValues>({
  name,
  control,
  checkboxProps,
}: ControlledCheckboxProps<T>) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box>
          <Checkbox
            {...checkboxProps}
            {...field}
            checked={field.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
              field.onChange(event);
              checkboxProps?.onChange?.(event, checked);
            }}
          />
        </Box>
      )}
    />
  );