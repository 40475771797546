import { useQueryClient } from "@tanstack/react-query";
import { match } from "ts-pattern";

import { queries } from "@/api/graphql/constants";
import { FormDataRuleGroupsFE } from "@/api/graphql/formDataInjector/getSearchFormDataRuleGroups/types";
import { ACTIONS } from "@/components/formDataInjector/constants";
import {
  HandleFormDataRuleGroupType,
  UseHandleFormDataRuleGroupProps,
} from "@/components/formDataInjector/types";

export const useHandleFormDataRuleGroup = ({
  resetState,
  addSnackbar,
  translateFunction,
}: UseHandleFormDataRuleGroupProps) => {
  const queryClient = useQueryClient();

  const invalidateQuery = () => {
    queryClient.invalidateQueries({
      queryKey: [queries.GET_SEARCH_FORM_DATA_RULE_GROUPS],
    });
  };

  const handleClick = (type: HandleFormDataRuleGroupType, id: string) =>
    match(type)
      .with(HandleFormDataRuleGroupType.TOGGLE, () =>
        ACTIONS.toggle(id)
          .then(() => {
            invalidateQuery();
            addSnackbar({
              dataTestId: "formdata-injector__success-snackbar",
              title: translateFunction(
                "formDataInjector.forms.toggle.success.title",
              ),
              message: translateFunction(
                "formDataInjector.forms.toggle.success.message",
              ),
              type: "success",
              isAutoClose: true,
              isOpen: true,
              onClose: () => {},
            });
          })
          .catch(() => {
            addSnackbar({
              dataTestId: "formdata-injector__error-snackbar",
              title: translateFunction(
                "formDataInjector.forms.toggle.error.title",
              ),
              message: translateFunction(
                "formDataInjector.forms.toggle.error.message",
              ),
              type: "error",
              isAutoClose: true,
              isOpen: true,
              onClose: () => {},
            });
          }),
      )
      .with(HandleFormDataRuleGroupType.DELETE, () =>
        ACTIONS.delete(id)
          .then(() => {
            invalidateQuery();
            addSnackbar({
              dataTestId: "formdata-injector__success-snackbar",
              title: translateFunction(
                "formDataInjector.forms.delete.success.title",
              ),
              message: translateFunction(
                "formDataInjector.forms.delete.success.message",
              ),
              type: "success",
              isAutoClose: true,
              isOpen: true,
              onClose: () => {},
            });
            resetState?.(undefined);
          })
          .catch(() => {
            addSnackbar({
              dataTestId: "formdata-injector__error-snackbar",
              title: translateFunction(
                "formDataInjector.forms.delete.error.title",
              ),
              message: translateFunction(
                "formDataInjector.forms.delete.error.message",
              ),
              type: "error",
              isAutoClose: true,
              isOpen: true,
              onClose: () => {},
            });
          }),
      )
      .with(HandleFormDataRuleGroupType.DUPLICATE, () =>
        ACTIONS.duplicate(id)
          .then(() => {
            invalidateQuery();
            addSnackbar({
              dataTestId: "formdata-injector__success-snackbar",
              title: translateFunction(
                "formDataInjector.forms.duplicate.success.title",
              ),
              message: translateFunction(
                "formDataInjector.forms.duplicate.success.message",
              ),
              type: "success",
              isAutoClose: true,
              isOpen: true,
              onClose: () => {},
            });
          })
          .catch(() => {
            addSnackbar({
              dataTestId: "formdata-injector__error-snackbar",
              title: translateFunction(
                "formDataInjector.forms.duplicate.error.title",
              ),
              message: translateFunction(
                "formDataInjector.forms.duplicate.error.message",
              ),
              type: "error",
              isAutoClose: true,
              isOpen: true,
              onClose: () => {},
            });
          }),
      );

  const handleSubmit = (
    type: HandleFormDataRuleGroupType,
    formData: FormDataRuleGroupsFE,
  ) =>
    match(type).with(HandleFormDataRuleGroupType.UPDATE, () =>
      ACTIONS.update(formData)
        .then(() => {
          invalidateQuery();
          addSnackbar({
            dataTestId: "formdata-injector__success-snackbar",
            title: translateFunction(
              "formDataInjector.forms.update.success.title",
            ),
            message: translateFunction(
              "formDataInjector.forms.update.success.message",
            ),
            type: "success",
            isAutoClose: true,
            isOpen: true,
            onClose: () => {},
          });
        })
        .catch(() => {
          addSnackbar({
            dataTestId: "formdata-injector__error-snackbar",
            title: translateFunction(
              "formDataInjector.forms.update.error.title",
            ),
            message: translateFunction(
              "formDataInjector.forms.update.error.message",
            ),
            type: "error",
            isAutoClose: true,
            isOpen: true,
            onClose: () => {},
          });
        }),
    );

  return { handleClick, handleSubmit };
};
