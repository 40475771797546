import { useTheme } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Check,
  Close,
  Document,
  Text,
  Toggle,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { EditFormProps } from "@/components/formDataInjector/EditForm/types";
import { useHandleFormDataRuleGroup } from "@/components/formDataInjector/hooks";
import {
  StyledButtonsContainer,
  StyledForm,
  StyledInputsContainer,
  StyledToggleContainer,
} from "@/components/formDataInjector/styles";
import {
  HandleFormDataRuleGroupType,
  ruleFormSchema,
  RuleFormType,
} from "@/components/formDataInjector/types";
import { ControlledTextField } from "@/components/shared/ControlledTextField";
import { YamlEditor } from "@/components/shared/YamlEditor";
import { useAddSnackbar } from "@/contexts/snackbar";

export const EditForm = ({ value, resetState }: EditFormProps) => {
  const { t: tFormDataInjector } = useTranslation("formDataInjector");
  const [disableDuplicate, setDisableDuplicate] = useState(false);
  const addSnackbar = useAddSnackbar();
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<RuleFormType>({
    resolver: zodResolver(ruleFormSchema),
    values: {
      name: value.name,
      description: value.description,
      rules: value.rules,
      id: value.id,
      enabled: value.enabled,
      enabledFor: value.enabledFor,
      disabledFor: value.disabledFor,
      codes: value.codes,
      numberOfRules: value.numberOfRules,
    },
  });

  const { handleClick, handleSubmit: handleSubmitFormDataInjector } =
    useHandleFormDataRuleGroup({
      resetState,
      addSnackbar,
      translateFunction: tFormDataInjector,
    });

  const theme = useTheme();

  useEffect(() => {
    if (disableDuplicate) {
      setTimeout(() => {
        setDisableDuplicate(false);
      }, 3000);
    }
  }, [disableDuplicate]);

  const onSubmit = (values: RuleFormType) => {
    handleSubmitFormDataInjector(HandleFormDataRuleGroupType.UPDATE, values);
  };

  const isButtonDisabled = Object.keys(errors).length > 0;

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledInputsContainer>
        <ControlledTextField
          name='name'
          control={control}
          namespace='formDataInjector'
          textFieldProps={{
            label: tFormDataInjector("formDataInjector.inputs.name"),
            dataTestId: "formdata-injector__rule-name-input",
          }}
          data-testid='formdata-injector__rule-name-input'
        />

        <ControlledTextField
          name='description'
          control={control}
          namespace='formDataInjector'
          textFieldProps={{
            label: tFormDataInjector("formDataInjector.inputs.description"),
            dataTestId: "formdata-injector__rule-description-input",
          }}
          data-testid='formdata-injector__rule-description-input'
        />

        <StyledToggleContainer>
          <Text variant='title1'>
            {tFormDataInjector("formDataInjector.inputs.activation")}
          </Text>
          <Controller
            name='enabled'
            control={control}
            render={({ field: { value, onChange } }) => (
              <Toggle
                isActive={value}
                onChange={onChange}
                data-testid='formdata-injector__toggle-button'
              />
            )}
          />
        </StyledToggleContainer>
      </StyledInputsContainer>

      <Controller
        name='rules'
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            <YamlEditor
              value={value}
              onChange={onChange}
              setError={(message) => {
                if (message) {
                  setError("rules", { type: "manual", message });
                } else {
                  clearErrors("rules");
                }
              }}
              height='400px'
            />
          </>
        )}
      />

      <StyledButtonsContainer>
        <Button
          type='submit'
          disabled={isButtonDisabled}
          leftIcon={
            <Check width={24} height={24} color={theme.palette.white} />
          }
          onClick={undefined}
          buttonText={tFormDataInjector("formDataInjector.buttons.save")}
          variant='primary'
          size='s'
          dataTestId='formdata-injector__save-button'
        />
        <Button
          onClick={() => {
            handleClick(HandleFormDataRuleGroupType.DUPLICATE, value.id ?? "");
            setDisableDuplicate(true);
          }}
          leftIcon={
            <Document width={24} height={24} color={theme.palette.white} />
          }
          buttonText={tFormDataInjector("formDataInjector.buttons.clone")}
          variant='primary'
          size='s'
          disabled={disableDuplicate}
          dataTestId='formdata-injector__clone-button'
        />
        <Button
          leftIcon={
            <Close width={18} height={18} color={theme.palette.white} />
          }
          onClick={() => {
            handleClick(HandleFormDataRuleGroupType.DELETE, value.id ?? "");
          }}
          buttonText={tFormDataInjector("formDataInjector.buttons.delete")}
          variant='primary'
          colorScheme='emergency'
          size='s'
          dataTestId='formdata-injector__delete-button'
        />
      </StyledButtonsContainer>
    </StyledForm>
  );
};
