import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Drawer,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useCreateFormDataRuleGroup } from "@/api/graphql/formDataInjector/createFormDataRuleGroup/hooks";
import { MiddleMsCreateFormDataRuleGroupRequestType } from "@/api/graphql/types/graphql";
import { ControlledSelect } from "@/components/shared/ControlledSelect";
import { ControlledTextField } from "@/components/shared/ControlledTextField";
import { ControlledCheckbox } from "@/components/shared/ControllerCheckbox";
import { YamlEditor } from "@/components/shared/YamlEditor";
import { useAddSnackbar } from "@/contexts/snackbar";
import {
  CreationFormRuleSchema,
  CreationFormRuleType,
} from "@/helpers/formDataInjector/helpers";

import {
  StyledDrawerInnerContainer,
  StyledForm,
  StyledInputContainer,
  StyledRoundedTopLeftContainer,
  StyledSection,
  StyledText,
} from "./styles";
import { RulesDrawerProps } from "./type";

export const RulesDrawer: FC<RulesDrawerProps> = ({
  isCreateModalOpen,
  setIsCreateModalOpen,
}) => {
  const { mutateAsync, isLoading } = useCreateFormDataRuleGroup();
  const addSnackbar = useAddSnackbar();
  const { t: tFormDataInjector } = useTranslation("formDataInjector");
  const { control, handleSubmit } = useForm<CreationFormRuleType>({
    resolver: zodResolver(CreationFormRuleSchema),
    defaultValues: {
      name: undefined,
      codes: [],
      description: undefined,
      enabled: false,
      rules: undefined,
      type: undefined,
    },
  });

  const onSubmit = (values: CreationFormRuleType) => {
    mutateAsync(values)
      .then(() => {
        addSnackbar({
          title: tFormDataInjector(
            "formDataInjector.forms.create.success.title",
          ),
          message: tFormDataInjector(
            "formDataInjector.forms.create.success.message",
          ),
          type: "success",
          dataTestId: "formdata-injector__success-snackbar",
          isAutoClose: true,
        });
        setIsCreateModalOpen(false);
      })
      .catch(() => {
        addSnackbar({
          title: tFormDataInjector("formDataInjector.forms.create.error.title"),
          message: tFormDataInjector(
            "formDataInjector.forms.create.error.message",
          ),
          type: "error",
          isAutoClose: true,
          dataTestId: "formdata-injector__error-snackbar",
        });
      });
  };

  return (
    <Drawer
      isOpen={isCreateModalOpen}
      width={"60%"}
      dataTestId='formdata-injector__create-rule-drawer'
      onClose={() => setIsCreateModalOpen(false)}
    >
      <StyledText sx={{ margin: "20px 20px 0 20px" }} variant='title5'>
        {tFormDataInjector("formDataInjector.drawer.title")}
      </StyledText>

      <StyledRoundedTopLeftContainer>
        <StyledDrawerInnerContainer>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <StyledSection>
              <StyledInputContainer>
                <StyledText variant='title2' fontWeight='medium'>
                  {tFormDataInjector("formDataInjector.forms.name")}
                </StyledText>
                <ControlledTextField
                  name='name'
                  control={control}
                  namespace='formDataInjectorCreation'
                  textFieldProps={{
                    label: tFormDataInjector("formDataInjector.forms.name"),
                    dataTestId: "formdata-injector__form-rule-name",
                  }}
                />
              </StyledInputContainer>

              <StyledInputContainer>
                <StyledText variant='title2' fontWeight='medium'>
                  {tFormDataInjector("formDataInjector.forms.description")}
                </StyledText>
                <ControlledTextField
                  name='description'
                  control={control}
                  namespace='formDataInjectorCreation'
                  textFieldProps={{
                    label: tFormDataInjector(
                      "formDataInjector.forms.description",
                    ),
                    dataTestId: "formdata-injector__form-rule-description",
                  }}
                />
              </StyledInputContainer>
              <StyledInputContainer>
                <StyledText variant='title2' fontWeight='medium'>
                  {tFormDataInjector("formDataInjector.forms.rules")}
                </StyledText>
                <Controller
                  name='rules'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <YamlEditor
                      defaultValue={value}
                      onChange={onChange}
                      dataTestId='formdata-injector__form-rule-rules'
                    />
                  )}
                />
              </StyledInputContainer>

              <StyledInputContainer>
                <StyledText variant='title2' fontWeight='medium'>
                  {tFormDataInjector("formDataInjector.forms.type")}
                </StyledText>
                <ControlledSelect
                  name='type'
                  control={control}
                  namespace='formDataInjectorCreation'
                  selectProps={{
                    label: tFormDataInjector("formDataInjector.forms.type"),
                    dataTestId: "formdata-injector__form-rule-type",
                    type: "options",
                    options: [
                      {
                        label: tFormDataInjector(
                          "formDataInjector.forms.createOrEdit",
                        ),
                        value:
                          MiddleMsCreateFormDataRuleGroupRequestType.FormRuleTypeCreationOrModification,
                      },
                      {
                        label: tFormDataInjector("formDataInjector.forms.copy"),
                        value:
                          MiddleMsCreateFormDataRuleGroupRequestType.FormRuleTypeCopy,
                      },
                    ],
                  }}
                />
              </StyledInputContainer>

              <StyledInputContainer>
                <StyledText variant='title2' fontWeight='medium'>
                  {tFormDataInjector("formDataInjector.forms.enabled")}
                </StyledText>
                <ControlledCheckbox name='enabled' control={control} />
              </StyledInputContainer>
              <Button
                type='submit'
                dataTestId='formdata-injector__create-rule-drawer-create-button'
                onClick={undefined}
                variant='primary'
                disabled={isLoading}
                buttonText={tFormDataInjector(
                  "formDataInjector.forms.create.button",
                )}
              />
            </StyledSection>
          </StyledForm>
        </StyledDrawerInnerContainer>
      </StyledRoundedTopLeftContainer>
    </Drawer>
  );
};
