import { getGraphqlClient } from "@/api/graphql/client.ts";
import { AccountsMsCancelSupportUserInviteRequest } from "@/api/graphql/types/graphql.ts";

import { cancelSupportAgentInviteMutation } from "./queries";

export const cancelSupportUserInviteQuery = (
  request: AccountsMsCancelSupportUserInviteRequest,
) => {
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request(cancelSupportAgentInviteMutation, { request });
};