import { useTranslation } from "react-i18next";

import { EntityType } from "@/api/graphql/globalSearch";
import { StyledDiv, StyledGroupText } from "@/components/Search/styles";
import { StyledText } from "@/components/shared/TabMenuButton/styles";

import { SearchGroupProps } from "./types";

const SearchGroup = ({
  params,
  totalUsersResults,
  totalAccountResults,
}: SearchGroupProps) => {
  const { t: tUsers } = useTranslation("users");
  const { t: tCompanies } = useTranslation("companies");

  return (
    <StyledDiv>
      <StyledGroupText variant='sub3'>
        <StyledText
          variant='text3'
          dataTestId={`entities__entities-search-group-${params.group}`}
        >
          {params.group === EntityType.User
            ? tUsers("users.list.userWithPlural.user", {
                count: Number(totalUsersResults),
              })
            : tCompanies("companies.list.companiesWithPlural.company", {
                count: Number(totalAccountResults),
              })}
        </StyledText>
      </StyledGroupText>
      {params.children}
    </StyledDiv>
  );
};

export default SearchGroup;
