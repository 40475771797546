import { useTheme } from "@emotion/react";
import {
  Button,
  Search,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { Add } from "@totalenergiescode/mobility-business-rev-design-system";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetSearchFormDataRuleGroupsQuery } from "@/api/graphql/formDataInjector/getSearchFormDataRuleGroups/hooks";
import { FormDataRuleGroupsFE } from "@/api/graphql/formDataInjector/getSearchFormDataRuleGroups/types";
import { EditForm } from "@/components/formDataInjector/EditForm";
import { Loading } from "@/components/shared/Loading";
import { useFilteredRules } from "@/hooks/formDataInjector/hooks";

import { StyledPageHeader } from "../migrationReport/styles";
import { RulesDrawer } from "./RulesDrawer/component";
import {
  StyledContainer,
  StyledDataContainer,
  StyledEmptyRule,
  StyledFormDataInjectorContainer,
  StyledRuleDescription,
  StyledRuleHeader,
  StyledRuleItem,
  StyledRuleList,
  StyledRuleName,
  StyledRulesFinder,
  StyledRuleStatus,
  StyledSearchField,
} from "./styles";

export const FormDataInjector = () => {
  const { t: tFormDataInjector } = useTranslation("formDataInjector");
  const theme = useTheme();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedRuleGroup, setSelectedRuleGroup] = useState<
    FormDataRuleGroupsFE | undefined
  >();
  const { data: rules, isLoading } = useGetSearchFormDataRuleGroupsQuery();
  const handleRuleGroupClick = (rule: FormDataRuleGroupsFE) => {
    setSelectedRuleGroup(rule);
  };
  const { queryString, filteredRules, handleSearchChange } =
    useFilteredRules(rules);

  const breadcrumbItems = [
    {
      title: tFormDataInjector("formDataInjector.breadcrumb"),
      href: "/formdata-injector",
      path: "/formdata-injector",
    },
  ];

  return (
    <>
      <StyledPageHeader
        breadcrumbItems={breadcrumbItems}
        title={tFormDataInjector("formDataInjector.title")}
        href=''
        data-testid='formdata-injector__page-header'
      />
      <StyledContainer data-testid='formdata-injector__container'>
        <StyledRulesFinder data-testid='formdata-injector__rules-finder'>
          <StyledSearchField
            dataTestId='formdata-injector__search-field'
            onChange={handleSearchChange}
            placeholder={tFormDataInjector(
              "formDataInjector.inputs.searchPlaceholder",
            )}
            startIcon={<Search width={18} height={18} />}
            value={queryString}
          />

          <div data-testid='formdata-injector__button-container'>
            <Button
              leftIcon={
                <Add width={18} height={18} color={theme.palette.white} />
              }
              onClick={() => setIsCreateModalOpen(true)}
              buttonText={tFormDataInjector("formDataInjector.buttons.create")}
              variant='primary'
              size='m'
              dataTestId='formdata-injector__create-rule-button'
            />
          </div>
        </StyledRulesFinder>

        <StyledDataContainer data-testid='formdata-injector__data-container'>
          <StyledRuleList data-testid='formdata-injector__rulelist'>
            <Loading isLoading={isLoading}>
              <>
                {filteredRules.length === 0 ? (
                  <StyledEmptyRule data-testid='formdata-injector__empty-rulelist'>
                    <Text variant='title2'>
                      {tFormDataInjector("formDataInjector.emptyRuleList")}
                    </Text>
                  </StyledEmptyRule>
                ) : (
                  filteredRules.map((rule) => (
                    <StyledRuleItem
                      key={rule.id}
                      onClick={() => handleRuleGroupClick(rule)}
                      isSelected={selectedRuleGroup?.id === rule.id}
                      data-testid={`formdata-injector__-rule-${rule.id}`}
                    >
                      <StyledRuleHeader data-testid='formdata-injector__rule-header'>
                        <StyledRuleName data-testid='formdata-injector__rule-name'>
                          {rule.name}
                        </StyledRuleName>
                        <StyledRuleStatus
                          isEnabled={rule.enabled ?? false}
                          data-testid='formdata-injector__rule-status'
                        >
                          {rule.enabled
                            ? tFormDataInjector("formDataInjector.list.enabled")
                            : tFormDataInjector(
                                "formDataInjector.list.disabled",
                              )}
                        </StyledRuleStatus>
                      </StyledRuleHeader>
                      <StyledRuleDescription data-testid='formdata-injector__rule-description'>
                        {rule.description}
                      </StyledRuleDescription>
                    </StyledRuleItem>
                  ))
                )}
              </>
            </Loading>
          </StyledRuleList>

          <StyledFormDataInjectorContainer data-testid='formdata-injector__form-container'>
            {selectedRuleGroup ? (
              <EditForm
                value={selectedRuleGroup}
                resetState={setSelectedRuleGroup}
                data-testid='formdata-injector__edit-form'
              />
            ) : (
              <StyledEmptyRule data-testid='formdata-injector__no-rule-selected'>
                <Text variant='title6'>
                  {tFormDataInjector("formDataInjector.noRuleSelected")}
                </Text>
                <Text variant='text3'>
                  {tFormDataInjector("formDataInjector.selectRule")}
                </Text>
              </StyledEmptyRule>
            )}
          </StyledFormDataInjectorContainer>
        </StyledDataContainer>

        <RulesDrawer
          isCreateModalOpen={isCreateModalOpen}
          setIsCreateModalOpen={setIsCreateModalOpen}
          data-testid='formdata-injector__create-rule-drawer'
        />
      </StyledContainer>
    </>
  );
};
