import { useDsTheme } from "@totalenergiescode/mobility-business-rev-design-system";
import { RFC } from "react";
import { useTranslation } from "react-i18next";

import { EmptyContent } from "@/components/shared/EmptyComponent";
import { StyledHighlightLeftCard } from "@/components/shared/EmptyComponent/styles";

import Confettis from "../../../assets/animations/Confettis.json";
import { Props } from "./types";

export const MigrationReportSuccessComponent: RFC<Props> = ({
  children,
  isSuccess,
}) => {
  const theme = useDsTheme();
  const { t: tMigrationReport } = useTranslation("migrationReport");

  return isSuccess ? (
    <StyledHighlightLeftCard
      highlightColor={theme.palette.secondary.green[500] || ""}
    >
      <EmptyContent
        principalText={tMigrationReport(
          "migrationReport.successMessage.principalText",
        )}
        secondaryText={tMigrationReport(
          "migrationReport.successMessage.secondaryText",
        )}
        lottie={Confettis}
      />
    </StyledHighlightLeftCard>
  ) : (
    children
  );
};
