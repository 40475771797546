import { EditorView } from "@codemirror/view";

export const YamlConfig = [
  EditorView.lineWrapping,
  EditorView.theme({
    "&": { fontSize: "14px", fontFamily: "monospace" },
    ".cm-lineNumbers": { display: "block" },
  }),
];

export const YAML_SIZE = "500px";

export const DEFAULT_YAML = `rules:
- name: "FIELDNAME"
  path: "PATH"
  action: "ACTION"
  properties:
    name: "NAME"
    type: "TYPE"
    hidden: false
    required: false
    writable: false
`;
