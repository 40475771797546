import React from "react";

import { StyledContainer } from "@/layouts/common/RoundedFullPage/styles";
import { RoundedFullPageProps } from "@/layouts/common/RoundedFullPage/types";

export const RoundedFullPage: React.RFC<
  RoundedFullPageProps & { className?: string }
> = ({ header, children, className, "data-testid": dataTestId }) => (
  <StyledContainer className={className} data-testid={dataTestId}>
    {header}
    {children}
  </StyledContainer>
);
