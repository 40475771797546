import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getGraphqlClient } from "@/api/graphql/client";
import { queries } from "@/api/graphql/constants";
import type { MiddleMsCreateFormDataRuleGroupRequest } from "@/api/graphql/types/graphql";

import { MiddleMsCreateFormDataRuleGroup } from "./mutations";

export const useCreateFormDataRuleGroup = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isSuccess, error, isPending } = useMutation({
    mutationFn: async (request: MiddleMsCreateFormDataRuleGroupRequest) => {
      const response = await getGraphqlClient().request(
        MiddleMsCreateFormDataRuleGroup,
        {
          request,
        },
      );

      return response.MiddleMsCreateFormDataRuleGroup;
    },
    onError: () => "error",
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queries.GET_SEARCH_FORM_DATA_RULE_GROUPS],
      });

      return "success";
    },
  });

  return { mutateAsync, isSuccess, error, isLoading: isPending };
};
